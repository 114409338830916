import React, { useContext, useEffect, useState } from "react";
import "./LLM.scss";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import LLMIcon from "../../../Assets/Images/LLMIcon.png";
import Buttons from "../../../Components/Buttons/Buttons";
import { PlusIcon } from "../../../Assets/icons";
import { useNavigate } from "react-router-dom";
import { deleteApi, getApi } from "../../../utils/apiService";
import { DELETE_LLM, GET_ALL_LLM_DETAILS } from "../../../utils/apiPath";
import { errorToast, successToast } from "../../../services/ToastHelper";
import { Oval } from "react-loader-spinner";
import { IconButton, Tooltip } from "@mui/material";
import { DeleteOutlined } from "@ant-design/icons";
import { UserContext } from "../../../store/SubcriptionStore";

const LLM = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [llmToDelete, setLlmToDelete] = useState(null);
  const { subscriptionStatus, fetchStatusData } = useContext(UserContext);

  const handleCreateLLM = () => {
    navigate("/enterprise-createllm");
  };

  const fetchAllDetails = async () => {
    setIsLoading(true);
    const { status, data } = await getApi(GET_ALL_LLM_DETAILS);
    if (status === 200) {
      setStoreData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllDetails();
    fetchStatusData();
  }, []);

  const handleLLMDetails = (llmData) => {
    navigate(`/llmdetails/${llmData.llmId}`, { state: { storeData: llmData } });
  };

  const confirmDeleteLLM = (llmId) => {
    setLlmToDelete(llmId);
    setShowConfirm(true);
  };

  const handleDeleteLLM = async (llmId) => {
    setIsLoading(true);
    const payload = {
      llmId: llmId,
    };
    const data = {
      headers: { "Content-Type": "application/json" },
      data: payload,
    };
    const { status, message } = await deleteApi(DELETE_LLM, data);
    if (status === 200) {
      setIsLoading(false);
      successToast(message);
      fetchAllDetails();
    } else {
      errorToast(message);
    }
    setIsLoading(false);
    setShowConfirm(false);
    setLlmToDelete(null);
  };

  const handleCancel = () => {
    setShowConfirm(false);
    setLlmToDelete(null);
  };

  return (
    <CustomLayout>
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}
      {subscriptionStatus?.subscriptionStatus ? (
        <div className="llm">
          <div className="llm_header">
            <div className="llm_header_button">
              <Buttons variant={"primary"} onClick={handleCreateLLM}>
                Create AI Agent
                <PlusIcon />
              </Buttons>
            </div>
          </div>
          <div className="llm_sec">
            {storeData.map((item) => (
              <div
                className="llm_sec_card"
                key={item.llmId}
                onClick={() => handleLLMDetails(item)}
              >
                <div className="llm_sec_card_top">
                  <img src={LLMIcon} alt="LLM" />
                  <Tooltip title="Delete LLM" placement="top" arrow>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        confirmDeleteLLM(item?.llmId);
                      }}
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="llm_sec_card_bottom">
                <h3>{item.llmName.length > 16 ? item.llmName.slice(0, 16) + "..." : item.llmName}</h3>
                  <span onClick={() => handleLLMDetails(item)}>
                    More Details
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="status">
          <h1>Please Subscribe to use this Feature</h1>
        </div>
      )}
      {showConfirm && (
        <div className="confirm2">
          <div className="confirm2_popup">
            <div className="confirm2_popup_inner">
              <h2>Are you sure you want to Delete this AI Agent</h2>
              <div className="confirm2_popup_buttons">
                <button onClick={() => handleDeleteLLM(llmToDelete)}>
                  Yes
                </button>
                <button onClick={handleCancel}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </CustomLayout>
  );
};

export default LLM;
