import React, { useEffect, useMemo, useState } from "react";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import "./AllLLMData.scss";
import Icons from "../../../Components/Table/Icon";
import Icon from "../../../Components/Table/Icon";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import { deleteApi, getApi, postApi } from "../../../utils/apiService";
import {
  GET_ASSIGNED_LLMS,
  GET_UNASSIGNED_LLMS,
  USER_ASSIGN_LLM,
  USER_UNASSIGN_LLM,
} from "../../../utils/apiPath";
import Buttons from "../../../Components/Buttons/Buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../../services/ToastHelper";
import { BackArrow } from "../../../Assets/icons";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const AllLLMData = () => {
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [assignllmsData, setAssignllmsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [toggleAssigned, setToggleAssigned] = useState(true);
  const location = useLocation();
  const { userId } = location?.state || {};
  const navigate = useNavigate();
  // Fetch Assigned LLMs
  const fetchAssignedLLMs = async (pageIndex, pageSize) => {
    setLoading(true);
    const { status, data } = await getApi(GET_ASSIGNED_LLMS, {
      params: {
        llmUserId: userId,
        searchLLMName: searchTerm,
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
    });
    if (status === 200 && data?.llmUserData) {
      const assigned = data?.llmUserData?.assignedLLMs || [];
      setAssignllmsData(assigned);
      setPageCount(data?.totalPages);
    } else {
      setAssignllmsData([]);
      setPageCount(0);
    }
    setLoading(false);
  };

  // Fetch Unassigned LLMs
  const fetchUnAssignedLLMs = async (pageIndex, pageSize) => {
    setLoading(true);
    const { status, data } = await getApi(GET_UNASSIGNED_LLMS, {
      params: {
        llmUserId: userId,
        searchLLMName: searchTerm,
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
    });
    if (status === 200) {
      const unassigned = data?.unassignedLLMs || [];
      setAssignllmsData(unassigned);
      setPageCount(data?.totalPages);
    } else {
      setAssignllmsData([]);
      setPageCount(0);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (toggleAssigned) {
      fetchAssignedLLMs(pageIndex, pageSize);
    } else {
      fetchUnAssignedLLMs(pageIndex, pageSize);
    }
  }, [toggleAssigned, pageIndex, pageSize, debouncedSearchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleAssignUnassign = async (llmId) => {
    if (toggleAssigned) {
      // Unassign (DELETE request)
      const { status, message } = await deleteApi(USER_UNASSIGN_LLM, {
        params: {
          llmId: llmId,
          llmUserId: userId,
        },
      });
      if (status === 200) {
        if (toggleAssigned) {
          fetchAssignedLLMs(pageIndex, pageSize);
        } else {
          fetchUnAssignedLLMs(pageIndex, pageSize);
        }
        successToast(message);
      } else {
        // Log the message for debugging
        console.log("API Error Message:", message);
        errorToast(message);
      }
    } else {
      // Assign (POST request)
      const payload = {
        llmId: llmId,
        llmUserId: userId,
      };
      const { status, message } = await postApi(USER_ASSIGN_LLM, payload);
      if (status === 200) {
        if (toggleAssigned) {
          fetchAssignedLLMs(pageIndex, pageSize);
        } else {
          fetchUnAssignedLLMs(pageIndex, pageSize);
        }
        successToast(message);
      } else {
        errorToast(message);
      }
    }
  };

  const getButtonText = () =>
    toggleAssigned ? "Remove AI Agent" : "Add AI Agent";

  const handleUserManagement = () => {
    navigate("/llm-users-data");
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: "Sl No.",
        accessor: (row, index) => index + 1 + pageIndex * pageSize,
      },
      {
        Header: "AI Ageny Name",
        accessor: "llmName",
        Cell: ({ value }) => (value ? capitalizeFirstLetter(value) : "N/A"),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Buttons
              variant="small"
              onClick={() => handleAssignUnassign(row.original.llmId)}
            >
              {getButtonText()}
            </Buttons>
          </div>
        ),
      },
    ],
    [pageIndex, pageSize, toggleAssigned]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    prepareRow,
  } = useTable(
    {
      columns: COLUMNS,
      data: assignllmsData,
      manualPagination: true,
      pageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  return (
    <CustomLayout>
      <div className="table">
        <div className="table_container">
          <div className="table_container_left">
            <div className="back" onClick={handleUserManagement}>
              <BackArrow />
            </div>
            <h4>
              {toggleAssigned
                ? "Assigned AI Agent Data"
                : "Unassigned AI Agent Data"}
            </h4>
          </div>
          <div className="table_container_right">
            <div className="table_container_right_input">
              <input
                type="text"
                value={searchTerm}
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="table_container_right_button">
              <Buttons
                variant="primary"
                onClick={() => setToggleAssigned(!toggleAssigned)}
              >
                {toggleAssigned
                  ? "View Unassigned AI Agent's"
                  : "View Assigned AI Agent's"}
              </Buttons>
            </div>
          </div>
        </div>
        <div className="table_sec">
          <div className="table_sec_cont">
            <div
              className="table_sec_cont_details"
              style={{ overflow: "hidden" }}
            >
              <table {...getTableProps()}>
                <thead>
                  {headerGroups?.map((headerGroup, index) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={`header-${index}`}
                    >
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={`column-${index}`}
                          scope="col"
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {loading ? (
                    <tr>
                      <td
                        colSpan={COLUMNS.length}
                        className="loading-container"
                      >
                        <div className="loading-indicator">Loading...</div>
                      </td>
                    </tr>
                  ) : assignllmsData?.length === 0 ? (
                    <tr>
                      <td colSpan={COLUMNS.length} className="no-data">
                        No Users
                      </td>
                    </tr>
                  ) : (
                    page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={`row-${index}`}>
                          {row.cells.map((cell, cellIndex) => (
                            <td
                              {...cell.getCellProps()}
                              key={`cell-${cellIndex}`}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table_page">
          <div className="table_page_section">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPageIndex(0);
              }}
            >
              {[5, 10].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
            <span>
              Page{" "}
              <span>
                {pageIndex + 1} of {pageCount}
              </span>
            </span>
          </div>
          <ul>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(0)}
                disabled={pageIndex === 0}
              >
                <Icons icon="heroicons:chevron-double-left-solid" />
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex - 1)}
                disabled={pageIndex === 0}
              >
                Prev
              </button>
            </li>

            {pageOptions?.map((page) => (
              <li key={page}>
                <button
                  style={{
                    backgroundColor: page === pageIndex ? "#2D3748" : "#EDF2F7",
                    color: page === pageIndex ? "#fff" : "#2D3748",
                  }}
                  onClick={() => setPageIndex(page)}
                >
                  {page + 1}
                </button>
              </li>
            ))}
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex + 1)}
                disabled={pageIndex === pageCount - 1}
              >
                Next
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageCount - 1)}
                disabled={pageIndex === pageCount - 1}
              >
                <Icon icon="heroicons:chevron-double-right-solid" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </CustomLayout>
  );
};

export default AllLLMData;
