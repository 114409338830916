import React, { useEffect, useState } from "react";
import "./UserApiKey.scss";
import { GoTrash } from "react-icons/go";
import { ThreeDots } from "react-loader-spinner";
import BasicTooltip from "../../../Components/Tooltip/Tooltip";
import {
  CREATE_USER_API_KEY,
  DELETE_USER_API_KEY,
  GET_USER_API_KEY,
} from "../../../utils/apiPath";
import { deleteApi, postApi } from "../../../utils/apiService";
import { errorToast, successToast } from "../../../services/ToastHelper";
import { useNavigate, useParams } from "react-router-dom";
import Confirm from "../../../Components/confirmModal/confirm";
import { BackArrow } from "../../../Assets/icons";
import UserLayout from "../../../Components/UserLayout/UserLayout";

const UserApiKey = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [apiName, setApiName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [messages, setMessages] = useState("");
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("Copy Api Key");
  const [tooltipTitleCurl, setTooltipTitleCurl] = useState("Copy Curl Code");
  const [tooltipTitleSuccess, setTooltipTitleSuccess] =
    useState("Copy Response");
  const [tooltipTitleBadRequest, setTooltipTitleBadRequest] =
    useState("Copy Response");
  const [tooltipTitleUnauthorized, setTooltipTitleUnauthorized] =
    useState("Copy Response");
  const [tooltipTitleInvalid, setTooltipTitleInvalid] =
    useState("Copy Response");
  // const [apiKeyCopied, setApiKeyCopied] = useState(false);
  // const [curlCodeCopied, setCurlCodeCopied] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleApiNameChange = (e) => {
    setApiName(e.target.value);
  };

  const handleCreateApiKey = async () => {
    if (rowsData.length > 0) {
      errorToast("You can only create one API key for this AI Agent.");
      return;
    }
    setIsLoading(true);
    const payload = {
      llmId: id,
      keyName: apiName,
    };
    const { status, data, message } = await postApi(CREATE_USER_API_KEY, payload);
    if (status === 200) {
      setIsLoading(false);
      setShowPopup(false);
      successToast(message);
      fetchGetApiKey();
    } else {
      setIsLoading(false);
      errorToast(message);
    }
  };

  const fetchGetApiKey = async () => {
    const payload = {
      llmId: id,
    };

    const { status, data } = await postApi(GET_USER_API_KEY, payload);
    if (status === 200 && data) {
      setRowsData(data);
    } else {
      setRowsData([]);
    }
  };

  useEffect(() => {
    fetchGetApiKey();
  }, []);

  const handleDelete = async (apiKeyId) => {
    const payload = {
      llmId: id,
      apiKeyId: apiKeyId,
    };
    const data = {
      headers: { "Content-Type": "application/json" },
      data: payload,
    };
    const { status, message } = await deleteApi(DELETE_USER_API_KEY, data);
    if (status === 200) {
      successToast(message);
      fetchGetApiKey();
    } else {
      errorToast(message);
    }
  };

  const handleCopyApiKey = (apiKey) => {
    navigator.clipboard
      .writeText(apiKey)
      .then(() => {
        console.log("API key copied to clipboard");
        setTooltipTitle("Copied!");
        setTimeout(() => {
          setTooltipTitle("Copy Api Key");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy API key: ", error);
      });
  };

  const handleCopyCurlCode = () => {
    const curlCode = `curl -X 'POST' \
    'https://multi-api.myaisquad.com/api/v1/textGPTChat/gptAPITextChat' \
    -H 'accept: application/json' \
    -H 'Authorization: Bearer your_api_key' \
    -H 'Content-Type: application/json' \
    -d '{
    "question": "Hello"
  }'`;
    navigator.clipboard
      .writeText(curlCode)
      .then(() => {
        console.log("Curl code copied to clipboard");
        setTooltipTitleCurl("Copied!");
        setTimeout(() => {
          setTooltipTitleCurl("Copy Curl Code");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy curl code: ", error);
      });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleApiDoc = () => {
    navigate("/enterprise/user/api-doc");
  };

  return (
    <UserLayout>
      <div className="api">
        {isLoading && (
          <div className="loader-container">
            <div className="loader">
              <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
            </div>
          </div>
        )}
        <div className="api_cont">
          <div className="api_sec">
            <div className="api_sec_header">
              <div className="api_sec_header_back" onClick={handleBack}>
                <BackArrow />
              </div>
              <h3>API Keys</h3>
              <button onClick={togglePopup}>Create Key</button>
            </div>
            <div className="api_sec_table">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Key</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {rowsData.map((rowData, index) => (
                    <tr key={index}>
                      <td>{rowData.keyName}</td>
                      <td>
                        <div className="apikeyrow">
                          {rowData.apiKey}{" "}
                          <BasicTooltip
                            className="copyicon"
                            onClick={() => handleCopyApiKey(rowData.apiKey)}
                            title={tooltipTitle}
                          />
                        </div>
                      </td>
                      <td>
                        <GoTrash
                          className="trash"
                          onClick={() => handleDelete(rowData.apiKeyId)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="api_sec_container">
              <div className="api_sec_container_document">
                <div className="api_sec_container_document_title">
                  <h3>Easy Guide to Using the Chatbot API</h3>
                  <p>
                    Welcome! This guide will help you use our Chatbot API, which
                    allows you to ask questions and receive responses based on
                    your trained data. Let's get started!
                  </p>
                </div>
                <div className="api_sec_container_document_points">
                  <h4>1. What is the Chatbot API?</h4>
                  <p>
                    Our Chatbot API lets you interact with a chatbot that
                    responds using the data you have trained it with. You can
                    ask questions and get intelligent, contextual replies.
                  </p>
                </div>
                <div className="api_sec_container_document_points">
                  <h4>2. Getting Your API Key</h4>
                  <p>
                    API Key: You need an API Key to access the Chatbot API. It's
                    a unique code that proves you're allowed to use the service.
                  </p>
                  <p>
                    Keep Your API Key Safe: It's your unique access code and
                    should not be shared.
                  </p>
                </div>
                <div className="api_sec_container_document_points">
                  <h4>3. How to Make a Request</h4>
                  <p>
                    To interact with the chatbot, send a request to our API.
                    Here's an example using cURL:
                  </p>
                </div>
              </div>
              <div className="api_sec_container_content">
                <div className="api_sec_container_content_left">
                  <h3>API Curl</h3>
                  <ul>
                    <li>
                      Replace your_api_key with the API key that you have
                      created.
                    </li>
                    <li>
                      In the request body, replace question with your question.
                    </li>
                    <li>
                      You can use the following curl command to make the API
                      request:
                    </li>
                  </ul>
                </div>
                <div className="api_sec_container_content_right">
                  <div className="api_sec_container_content_right_header">
                    <div className="api_sec_container_content_right_header_left">
                      <h3>Curl</h3>
                    </div>
                    <div className="api_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={handleCopyCurlCode}
                        title={tooltipTitleCurl}
                      />
                    </div>
                  </div>
                  <div className="api_sec_container_content_right_curl">
                    <pre>
                      <code>curl -X 'POST' \</code>
                      <br />
                      <code>
                        'https://multi-api.myaisquad.com/api/v1/textGPTChat/gptAPITextChat'
                        \
                      </code>
                      <br />
                      <code>-H 'accept: application/json' \</code>
                      <br />
                      <code>-H 'Authorization: Bearer your_api_key' \</code>
                      <br />
                      <code>-H 'Content-Type: application/json' \</code>
                      <br />
                      <code>-d {`'{`}</code>
                      <code>"question": "Your question here"</code>
                      <code>{`}'`}</code>
                      <br />
                    </pre>
                  </div>
                </div>
              </div>
            </div>
            <div className="api_sec_document">
              <p>
                For Full API Documentation Please Visit:{" "}
                <span onClick={handleApiDoc}>API Document</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="confirm">
          <div className="confirm_popup">
            <div className="confirm_popup_inner">
              <h2>Create API Key</h2>
              <label>Name Your Key</label>
              <input
                type="text"
                placeholder="Enter API Name"
                value={apiName}
                onChange={handleApiNameChange}
              />
              <div className="confirm_popup_buttons">
                <button onClick={togglePopup}>Cancel</button>
                <button onClick={handleCreateApiKey}>Create Key</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showInvalidUser && (
        <Confirm
          onCancel={() => setShowInvalidUser(false)}
          cancelButtonText="OK"
          isCancelRequired={true}
          confirmTitle={messages}
        />
      )}
    </UserLayout>
  );
};

export default UserApiKey;
