import React, { useState } from "react";
import "./NewsLetter.scss";
import AdminLayout from "../../../Components/AdminLayout/AdminLayout";
import { errorToast, successToast } from "../../../services/ToastHelper";
import Buttons from "../../../Components/Buttons/Buttons";
import { ADMIN_NEWS_LETTER } from "../../../utils/apiPath";
import { postApi } from "../../../utils/apiService";
import FormInputs from "../../../Components/FormInputs/FormInputs";

const initialValues = {
  subject: "",
  message: "",
};
const NewsLetter = () => {
  const [newsData, setNewsData] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewsData({
      ...newsData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!newsData.subject) {
      errObj.subject = "This field is required";
    } else {
      errObj.subject = "";
    }

    if (!newsData.message) {
      errObj.message = "This field is required";
    } else {
      errObj.message = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const valid = Object.values(errObj).every((x) => x === "" || x === null);
    return valid;
  };

  const handleNewsSubmit = async () => {
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        subject: newsData.subject,
        msgContent: newsData.message,
      };
      const { status, message } = await postApi(ADMIN_NEWS_LETTER, payload);
      if (status === 200) {
        setIsLoading(false);
        successToast(message);
      } else {
        errorToast(message);
        setIsLoading(false);
      }
    }
  };

  return (
    <AdminLayout>
      <div className="news">
        <div className="news_header">
          <h4>Send Mail To Subscribers</h4>
        </div>
        <div className="news_content">
          <div className="news_content_inputs">
            <label htmlFor="subject">Subject*</label>
            <input
              type="text"
              placeholder={"Enter Subject"}
              onChange={handleChange}
              name="subject"
              value={newsData.subject}
            />
            <div>
              {errors.subject && (
                <span className="error">{errors.subject}</span>
              )}
            </div>
          </div>
          <div className="news_content_inputs">
            <label htmlFor="message">Message*</label>
            <textarea
              id="message"
              placeholder="Enter Message"
              onChange={handleChange}
              name="message"
              value={newsData.message}
              rows={5}
            />
            <div>
              {errors.message && (
                <span className="error">{errors.message}</span>
              )}
            </div>
          </div>
          <div className="news_content_submit">
            <Buttons
              variant="primary"
              onClick={handleNewsSubmit}
              disabled={isLoading}
            >
              {isLoading ? "Sending..." : "Send Mail"}
            </Buttons>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default NewsLetter;
