import React, { useEffect, useState, useMemo, useCallback } from "react";
import "./CustomPayment.scss";
import AdminLayout from "../../../Components/AdminLayout/AdminLayout";
import { useTable, useRowSelect, useSortBy, usePagination } from "react-table";
import Icon from "../../../Components/Table/Icon";
import { getApi, postApi } from "../../../utils/apiService";
import {
  ADD_CUSTOM_PLAN,
  INACTIVE_USERS,
  PRICING_BREAKDOWN_API,
} from "../../../utils/apiPath";
import Buttons from "../../../Components/Buttons/Buttons";
import { debounce } from "lodash";
import { ClassSharp } from "@mui/icons-material";
import { errorToast, successToast } from "../../../services/ToastHelper";

const CustomPayment = () => {
  const [subscriberDetails, setSubscriberDetails] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [priceModal, setPriceModal] = useState(false);
  const [amount, setAmount] = useState("");
  const [customAmount, setCustomAmount] = useState("");
  const [customDate, setCustomDate] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [customPopup, setCustomPopup] = useState(false);
  const [selectedEnterpriseId, setSelectedEnterpriseId] = useState("");

  const togglePopup = () => {
    setPriceModal(false);
    setApiResponse(null);
    setAmount("");
  };
  const toggleCustomPopup = () => {
    setCustomPopup(false);
    setCustomAmount("");
    setCustomDate("");
  };

  const handleAssignUnassign = (enterpriseId) => {
    setSelectedEnterpriseId(enterpriseId);
    setCustomPopup(true);
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: "Sl No.",
        accessor: (row, index) => index + 1 + pageIndex * pageSize,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Buttons
              variant="secondary"
              onClick={() => handleAssignUnassign(row.original.enterpriseId)}
            >
              Add Plan
            </Buttons>
          </div>
        ),
      },
    ],
    [pageIndex, pageSize]
  );

  const fetchUserDetails = async (pageIndex, pageSize, searchQuery = "") => {
    setLoading(true);
    const { status, data } = await getApi(INACTIVE_USERS, {
      params: { page: pageIndex, pageSize: pageSize, search: searchQuery },
    });
    if (status === 200) {
      setSubscriberDetails(data?.usersData);
      setPageCount(data?.totalPages);
    } else {
      setSubscriberDetails([]);
      setPageCount(0);
    }
    setLoading(false);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    prepareRow,
  } = useTable(
    {
      columns: COLUMNS,
      data: subscriberDetails,
      manualPagination: true,
      pageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    fetchUserDetails(pageIndex, pageSize, searchQuery);
  }, [pageIndex, pageSize, searchQuery]);

  const handleSearchChange = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      setPageIndex(0);
    }, 500),
    []
  );

  const handlePricePopUp = () => {
    setPriceModal(true);
  };

  const handleCheckPrice = async () => {
    const { status, data } = await getApi(PRICING_BREAKDOWN_API, {
      params: { amount: amount },
    });
    if (status === 200) {
      setApiResponse(data);
    } else {
      setApiResponse(null);
    }
  };

  const handleCustomPrice = async () => {
    const payload = {
      amount: Number(customAmount),
      endDate: customDate,
      enterpriseId: selectedEnterpriseId,
      subscriptionType: "custom",
      isSubscribe: true,
    };
    const { status, message } = await postApi(ADD_CUSTOM_PLAN, payload);
    if (status === 200) {
      successToast(message);
      toggleCustomPopup();
      fetchUserDetails(pageIndex, pageSize, searchQuery);
    } else {
      errorToast(message);
      toggleCustomPopup();
      fetchUserDetails(pageIndex, pageSize, searchQuery);
    }
  };

  return (
    <AdminLayout>
      <div className="table">
        <div className="table_container">
          <h4>Unsubscribed Enterprise Users</h4>
          <div className="table_container_search">
            <Buttons variant="primary" onClick={handlePricePopUp}>
              Price Breakdown
            </Buttons>
            <input
              type="text"
              placeholder="Search by name..."
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </div>
        </div>
        <div className="table_sec">
          <div className="table_sec_cont">
            <div
              className="table_sec_cont_details"
              style={{ overflow: "hidden" }}
            >
              <table {...getTableProps}>
                <thead>
                  {headerGroups?.map((headerGroup, index) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={`header-${index}`}
                    >
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={`column-${index}`}
                          scope="col"
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {loading ? (
                    <tr>
                      <td
                        colSpan={COLUMNS.length}
                        className="loading-container"
                      >
                        <div className="loading-indicator">Loading...</div>
                      </td>
                    </tr>
                  ) : (
                    page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={`row-${index}`}>
                          {row.cells.map((cell, cellIndex) => (
                            <td
                              {...cell.getCellProps()}
                              key={`cell-${cellIndex}`}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table_page">
          <div className="table_page_section">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPageIndex(0);
              }}
            >
              {[10, 25, 50].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
            <span>
              Page{" "}
              <span>
                {pageIndex + 1} of {pageCount}
              </span>
            </span>
          </div>
          <ul>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(0)}
                disabled={pageIndex === 0}
              >
                <Icon icon="heroicons:chevron-double-left-solid" />
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex - 1)}
                disabled={pageIndex === 0}
              >
                Prev
              </button>
            </li>
            {pageOptions?.map((page) => (
              <li key={page}>
                <button
                  style={{
                    backgroundColor: page === pageIndex ? "#2D3748" : "#EDF2F7",
                    color: page === pageIndex ? "#fff" : "#2D3748",
                  }}
                  onClick={() => setPageIndex(page)}
                >
                  {page + 1}
                </button>
              </li>
            ))}
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex + 1)}
                disabled={pageIndex === pageCount - 1}
              >
                Next
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageCount - 1)}
                disabled={pageIndex === pageCount - 1}
              >
                <Icon icon="heroicons:chevron-double-right-solid" />
              </button>
            </li>
          </ul>
        </div>
      </div>
      {priceModal && (
        <div className="custom">
          <div className="bd_popup">
            <div className="bd_popup_inner">
              <h2>Check Payment Breakdown</h2>
              <div
                style={{ display: "flex", gap: "10px", marginBottom: "20px" }}
              >
                <input
                  type="number"
                  placeholder="$Enter Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <Buttons variant="primary" onClick={handleCheckPrice}>
                  Check
                </Buttons>
              </div>

              {apiResponse && (
                <div className="pricing-details">
                  <p>
                    Total Amount: <strong>${apiResponse?.totalAmount}</strong>
                  </p>
                  <p>
                    Allocated for Tokens:{" "}
                    <strong>${apiResponse?.allocatedForTokens}</strong>
                  </p>
                  <p>
                    Allocated for Services:{" "}
                    <strong>${apiResponse?.allocatedForServices}</strong>
                  </p>
                  <p>
                    Input Tokens: <strong>{apiResponse?.inputTokens}</strong>
                  </p>
                  <p>
                    Output Tokens: <strong>{apiResponse?.outputTokens}</strong>
                  </p>
                  <p>
                    Total Requests:{" "}
                    <strong>{apiResponse?.totalRequests}</strong>
                  </p>
                </div>
              )}

              <div className="bd_popup_buttons">
                <Buttons variant="secondary" onClick={togglePopup}>
                  Close
                </Buttons>
              </div>
            </div>
          </div>
        </div>
      )}
      {customPopup && (
        <div className="custom">
          <div className="custom_popup">
            <div className="custom_popup_inner">
              <h2>Add Custom Plan</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  marginBottom: "20px",
                }}
              >
                <div>
                  <label htmlFor="">Enter Custom Amount</label>
                  <input
                    type="number"
                    placeholder="$Enter Amount"
                    value={customAmount}
                    onChange={(e) => setCustomAmount(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="">Select End Date</label>
                  <input
                    type="date"
                    placeholder="Enter Amount"
                    value={customDate}
                    onChange={(e) => setCustomDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="custom_popup_buttons">
                <Buttons variant="secondary" onClick={toggleCustomPopup}>
                  Close
                </Buttons>
                <Buttons variant="primary" onClick={handleCustomPrice}>
                  Submit
                </Buttons>
              </div>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default CustomPayment;
