import React, { useEffect, useMemo, useState } from "react";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import "./PaymentHistory.scss";
import Icons from "../../../Components/Table/Icon";
import Icon from "../../../Components/Table/Icon";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import { getApi } from "../../../utils/apiService";
import { PAYMENT_HISTORY } from "../../../utils/apiPath";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Tooltip } from "@mui/material";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const PaymentHistory = () => {
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [paymentDetails, setPaymentDetails] = useState([]);

  const fetchPaymentHistory = async (pageIndex, pageSize) => {
    setLoading(true);
    const { status, data } = await getApi(PAYMENT_HISTORY, {
      params: { pageIndex: pageIndex, pageSize: pageSize },
    });
    if (status === 200) {
      setPaymentDetails(data);
      const calculatedPageCount =
        data.length < pageSize ? pageIndex + 1 : pageIndex + 2;
      setPageCount(calculatedPageCount);
    } else {
      setPaymentDetails([]);
      setPageCount(0);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPaymentHistory(pageIndex, pageSize);
  }, [pageIndex, pageSize]);

  console.log("paymentDetails:", paymentDetails);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().padStart(4, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: "Sl No.",
        accessor: (row, index) => index + 1 + pageIndex * pageSize,
      },
      {
        Header: "Subscription Type",
        accessor: "subscriptionType",
        Cell: ({ value }) => (value ? capitalizeFirstLetter(value) : "N/A"),
      },
      {
        Header: "Payment Status",
        accessor: "status",
        Cell: ({ value }) => (value ? capitalizeFirstLetter(value) : "N/A"),
      },
      {
        Header: "Time",
        accessor: "timestamp",
        Cell: ({ value }) => (value ? formatDate(value) : "N/A"),
      },
      {
        Header: "Invoice",
        accessor: "invoiceURL",
        Cell: ({ value }) =>
          value ? (
            <Tooltip title="Invoice Link" placement="top">
              <a href={value} target="_blank" rel="noopener noreferrer">
                <DescriptionOutlinedIcon />
              </a>
            </Tooltip>
          ) : (
            "N/A"
          ),
      },
    ],
    [pageIndex, pageSize]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    prepareRow,
  } = useTable(
    {
      columns: COLUMNS,
      data: paymentDetails,
      manualPagination: true,
      pageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  return (
    <CustomLayout>
      <div className="table">
        <div className="table_container">
          <h4>Payment History</h4>
        </div>
        <div className="table_sec">
          <div className="table_sec_cont">
            <div
              className="table_sec_cont_details"
              style={{ overflow: "hidden" }}
            >
              <table {...getTableProps()}>
                <thead>
                  {headerGroups?.map((headerGroup, index) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={`header-${index}`}
                    >
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={`column-${index}`}
                          scope="col"
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {loading ? (
                    <tr>
                      <td
                        colSpan={COLUMNS.length}
                        className="loading-container"
                      >
                        <div className="loading-indicator">Loading...</div>
                      </td>
                    </tr>
                  ) : paymentDetails.length === 0 ? (
                    <tr>
                      <td colSpan={COLUMNS.length} className="no-data">
                        No Transaction Data
                      </td>
                    </tr>
                  ) : (
                    page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={`row-${index}`}>
                          {row.cells.map((cell, cellIndex) => (
                            <td
                              {...cell.getCellProps()}
                              key={`cell-${cellIndex}`}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table_page">
          <div className="table_page_section">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPageIndex(0);
              }}
            >
              {[5, 10].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
            <span>
              Page{" "}
              <span>
                {pageIndex + 1} of {pageCount}
              </span>
            </span>
          </div>
          <ul>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(0)}
                disabled={pageIndex === 0}
              >
                <Icons icon="heroicons:chevron-double-left-solid" />
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex - 1)}
                disabled={pageIndex === 0}
              >
                Prev
              </button>
            </li>

            {pageOptions?.map((page) => (
              <li key={page}>
                <button
                  style={{
                    backgroundColor: page === pageIndex ? "#2D3748" : "#EDF2F7",
                    color: page === pageIndex ? "#fff" : "#2D3748",
                  }}
                  onClick={() => setPageIndex(page)}
                >
                  {page + 1}
                </button>
              </li>
            ))}
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex + 1)}
                disabled={pageIndex === pageCount - 1}
              >
                Next
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageCount - 1)}
                disabled={pageIndex === pageCount - 1}
              >
                <Icon icon="heroicons:chevron-double-right-solid" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </CustomLayout>
  );
};

export default PaymentHistory;
