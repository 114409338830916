import React, { useState } from "react";
import "../EnterpriseUserLogin/EnterpriseUserLogin.scss";
import { Oval } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import Buttons from "../../../Components/Buttons/Buttons";
import { errorToast, successToast } from "../../../services/ToastHelper";
import { LLM_USER_RESETPASSWORD } from "../../../utils/apiPath";
import { postApi } from "../../../utils/apiService";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const EnterpriseUserReset = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  const handleChange = (name, value) => {

    setLogin({
      ...login,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.password) {
        errObj.password = "This field is required";
      } else if (login.password.length < 8) {
        errObj.password = "Password must be 8 characters";
      } else {
        errObj.password = "";
      }
  
      if (!login.confirmPassword) {
        errObj.confirmPassword = "This field is required";
      } else if (login.password !== login.confirmPassword) {
        errObj.confirmPassword = "Passwords do not match";
      } else {
        errObj.confirmPassword = "";
      }
  
    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenFromUrl = searchParams.get("token");

  const handleResetPassword = async () => {
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        newPassword: login.confirmPassword,
        token: tokenFromUrl,
      };
      const { status, message } = await postApi(
        LLM_USER_RESETPASSWORD,
        payload
      );
      if (status === 200) {
        setIsLoading(false);
        navigate("/enterprise/user/login");
        successToast(message);
      } else {
         errorToast(message);
        setIsLoading(false);
      }
    }
  };
 
  return (
    <div className="login">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
          <Oval color="#86d3ff" height={50} width={50} radius="7" secondaryColor="#86d3ff"/>
          </div>
        </div>
      )}
      <div className="login_cont">
          <div className="login_cont_section">
            <div className="login_cont_section_header">
              <h3>Create New Password</h3>
            </div>
            <div className="login_cont_section_inputs">
              <div className="login_cont_section_inputs_data">
              <FormInputs
                  title={"Password"}
                  type={"password"}
                  placeholder={"********"}
                  name="password"
                  icon="icon2"
                  value={login.password}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleResetPassword();
                    }
                  }}
                />
                {errors.password && (
                        <span className="error">{errors.password}</span>
                      )}
              </div>
              <div className="login_cont_section_inputs_data">
                <FormInputs
                  title={"Confirm Password"}
                  type={"password"}
                  placeholder={"********"}
                  name="confirmPassword"
                  icon="icon2"
                  value={login.confirmPassword}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleResetPassword();
                    }
                  }}
                />
                {errors.confirmPassword && (
                        <span className="error">{errors.confirmPassword}</span>
                      )}
              </div>
            </div>
            <div className="login_cont_section_buttons">
              <Buttons variant="primary" onClick={handleResetPassword}>
              Set Password
            </Buttons>
            </div>
          </div>
        </div>
    </div>
  );
};

export default EnterpriseUserReset;
