import React, { useState } from "react";
import "../Login/Login.scss";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import Buttons from "../../../Components/Buttons/Buttons";
import { errorToast, successToast } from "../../../services/ToastHelper";
import { ENTERPRISE_SIGNUP } from "../../../utils/apiPath";
import { postApi } from "../../../utils/apiService";

const initialValues = {
  companyName: "",
  fullName: "",
  email: "",
  password: "",
};

const SignUp = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (name, value) => {

    setLogin({
      ...login,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.companyName) {
      errObj.companyName = "This field is required";
    } else {
      errObj.companyName = "";
    }

    if (!login.fullName) {
      errObj.fullName = "This field is required";
    } else {
      errObj.fullName = "";
    }

    if (!login.email) {
      errObj.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(login.email)) {
      errObj.email = "Enter Valid Email Id";
    } else {
      errObj.email = "";
    }

    if (!login.password) {
      errObj.password = "This field is required";
    } else if (login.password.length < 8) {
      errObj.password = "Password must be at least 8 characters";
    } else {
      errObj.password = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleSignUp = async () => {
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        organization: login.companyName,
        fullName: login.fullName,
        email: login.email,
        password: login.password,
      };
      const { status, message } = await postApi(ENTERPRISE_SIGNUP, payload);
      if (status === 200) {
        setIsLoading(false);
        successToast(message);
        navigate("/");
      } else {
        errorToast(message);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const handleSignInPage = () => {
    navigate("/");
  };

  return (
    <div className="login">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}
      <div className="login_cont">
        <div className="login_cont_section">
          <div className="login_cont_section_header">
            <h3>Enterprise Sign Up</h3>
          </div>
          <div className="login_cont_section_inputs">
            <div className="login_cont_section_inputs_data">
              <FormInputs
                title={"Enter Organization Name"}
                type={"text"}
                placeholder={"Enter Organization Name"}
                name="companyName"
                icon="icon5"
                value={login.companyName}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSignUp();
                  }
                }}
              />
              {errors.companyName && (
                <span className="error">{errors.companyName}</span>
              )}
            </div>
            <div className="login_cont_section_inputs_data">
              <FormInputs
                title={"Enter Full Name"}
                type={"text"}
                placeholder={"Enter Full Name"}
                name="fullName"
                icon="icon4"
                value={login.fullName}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSignUp();
                  }
                }}
              />
              {errors.fullName && (
                <span className="error">{errors.fullName}</span>
              )}
            </div>
            <div className="login_cont_section_inputs_data">
              <FormInputs
                title={"Enter Email"}
                type={"email"}
                placeholder={"Enter Your Email"}
                name="email"
                icon="icon1"
                value={login.email}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSignUp();
                  }
                }}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div className="login_cont_section_inputs_data">
              <FormInputs
                title={"Password"}
                type={"password"}
                placeholder={"********"}
                name="password"
                icon="icon2"
                value={login.password}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSignUp();
                  }
                }}
              />
              {errors.password && (
                <span className="error">{errors.password}</span>
              )}
            </div>
          </div>
          <div className="login_cont_section_buttons">
            <Buttons variant="primary" onClick={handleSignUp}>
              Sign Up
            </Buttons>
          </div>
          <div className="login_cont_section_bottom">
            <p>
              Do you have an account?
              <span onClick={handleSignInPage}>Sign In</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
