import axios from "axios";
import { BASE_ADMIN_API_URL } from "./constant";
import { ADMIN_REFRESH_TOKEN, ENTERPRISE_REFRESH_TOKEN } from "./apiPath";

axios.defaults.baseURL = BASE_ADMIN_API_URL;

axios.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});

const refreshToken = async () => {
  const storedRefreshToken = localStorage.getItem("refreshToken");
  const userRole = localStorage.getItem("userRole");

  if (!storedRefreshToken || !userRole) {
    throw new Error("Missing refresh token or user role");
  }

  let refreshUrl;
  if (userRole === "admin") {
    refreshUrl = ADMIN_REFRESH_TOKEN;
  } else if (userRole === "enterprise") {
    refreshUrl = ENTERPRISE_REFRESH_TOKEN;
  } else {
    throw new Error("Invalid user role");
  }

  try {
    const response = await axios.post(refreshUrl, {
      token: storedRefreshToken,
    });
    const { accessToken, refreshToken: newRefreshToken } = response.data;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", newRefreshToken);
    return accessToken;
  } catch (error) {
    throw new Error("Failed to refresh token");
  }
};

axios.interceptors.response.use(
  (response) => {
    if (
      response.status !== 200 ||
      (Object.hasOwn(response.data || {}, "success") &&
        !response.data.success) ||
      (Object.hasOwn(response.data || {}, "statusCode") &&
        response.data.statusCode !== 200)
    ) {
      throw new Error(response.data?.message);
    }
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;
    const userRole = localStorage.getItem("userRole");

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshToken();
        axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        // if (userRole === "admin") {
        //   window.location.href = "/admin-login";
        // } else if (userRole === "enterprise") {
        //   window.location.href = "/";
        // } else {
        //   window.location.href = "/enterprise/user/login";
        // }
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export const apiRequest = async ({ method, url, req, params }) => {
  try {
    if (method === "get") return await axios[method](url, params);
    return await axios[method](url, req, params);
  } catch (error) {
    return { error: error.message };
  }
};

export const getApi = async (url, params) =>
  await apiRequest({ method: "get", url, params });
export const postApi = async (url, req, params) =>
  await apiRequest({ method: "post", url, req, params });
export const putApi = async (url, req, params) =>
  await apiRequest({ method: "put", url, req, params });
export const deleteApi = async (url, req, params) =>
  await apiRequest({ method: "delete", url, req, params });
export const patchApi = async (url, req, params) =>
  await apiRequest({ method: "patch", url, req, params });
