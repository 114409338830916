import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import UserIcon from "../../../Assets/Images/UserIcon.png";
import LLMIcon from "../../../Assets/Images/LLMIcon.png";
import PaymentIcon from "../../../Assets/Images/PriceIcon.png";
import { useNavigate } from "react-router-dom";
import { getApi } from "../../../utils/apiService";
import { errorToast } from "../../../services/ToastHelper";
import { GET_ALL_LLM_COUNT } from "../../../utils/apiPath";
import { Oval } from "react-loader-spinner";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [countData, setCountData] = useState([]);

  const handleLLM = () => {
    navigate("/enterprise-llm");
  };

  const fetchAllDetails = async () => {
    setIsLoading(true);
    const { status, data, message } = await getApi(GET_ALL_LLM_COUNT);
    if (status === 200) {
      setIsLoading(false);
      setCountData(data);
    } else {
      setIsLoading(false);
      errorToast(message);
    }
  };

  useEffect(() => {
    fetchAllDetails();
  }, []);

  const handlePayment = () => {
    navigate("/subscription");
  };

  return (
    <CustomLayout>
      {isLoading && (
        <div className="loader-container">
          <Oval
            color="#86d3ff"
            height={50}
            width={50}
            radius="7"
            secondaryColor="#86d3ff"
          />
        </div>
      )}
      <div className="db">
        <div className="db_sec">
          <div className="db_sec_card" onClick={handleLLM}>
            <div className="db_sec_card_top">
              <img src={LLMIcon} alt="LLM" />
              <h2>{countData?.llmCount || 0}</h2>
            </div>
            <div className="db_sec_card_bottom">
              <h3>Number Of AI Agent's</h3>
            </div>
          </div>
          <div className="db_sec_card" onClick={handlePayment}>
            <div className="db_sec_card_top">
              <img src={PaymentIcon} alt="payment" />
              {/* <h2>{countData.paymentStatus}</h2> */}
            </div>
            <div className="db_sec_card_bottom">
              <h3>Payment Details</h3>
            </div>
          </div>
        </div>
      </div>
    </CustomLayout>
  );
};

export default Dashboard;
