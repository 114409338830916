import React, { useEffect, useState } from "react";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import Buttons from "../../../Components/Buttons/Buttons";
import "./Subscription.scss";
import { RightIcon } from "../../../Assets/icons";
import { useNavigate } from "react-router-dom";
import {
  CHECK_PAYMENT_STATUS,
  CUSTOM_PAYMENT_PLAN,
  PAYMENT_API,
} from "../../../utils/apiPath";
import { getApi, postApi } from "../../../utils/apiService";
import { Oval } from "react-loader-spinner";
import { errorToast, successToast } from "../../../services/ToastHelper";

const Subscription = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [statusData, setStatusData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const navigate = useNavigate();

  const fetchPaymentStatus = async () => {
    setIsLoading(true);
    const { status, data } = await getApi(CHECK_PAYMENT_STATUS);
    if (status === 200) {
      setStatusData(data);
    } else {
      setStatusData(null);
    }
    setIsLoading(false);
    setHasFetched(true);
  };

  useEffect(() => {
    fetchPaymentStatus();
  }, []);

  const handlePayment = async (subscriptionType) => {
    if (statusData) {
      setShowPopup(true);
      return;
    }
    setIsLoading(true);
    const payload = {
      subscriptionType,
    };
    const { status, data, message } = await postApi(PAYMENT_API, payload);
    if (status === 200) {
      setIsLoading(false);
      window.location.href = data?.url;
    } else {
      setIsLoading(false);
      errorToast(message);
    }
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().padStart(4, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const handleContactUs = async () => {
    setIsLoading(true);
    const { status, message } = await postApi(CUSTOM_PAYMENT_PLAN);
    if (status === 200) {
      setIsLoading(false);
      successToast(message);
    } else {
      setIsLoading(false);
      errorToast(message);
    }
  };

  const plans = [
    {
      type: "standard",
      price: "$1000",
      title: "Plan A",
      info: "Unlimited access to all features.",
      info2: "Billed Annually",
      features: [
        "It will provide 500M Input and 200M Output Tokens per month",
        "It will provide 6B Input and 2.4B Output Tokens per year",
        "You can Create and Train your own AI Agent",
        "Retrieving data from AI Agent",
        "Generate API keys for third-party access.",
      ],
      buttonLabel: "Buy Standard Plan",
    },
    {
      type: "plus",
      price: "$4950",
      title: "Plan B",
      info: "Unlimited access to all features.",
      info2: "Billed Annually",
      features: [
        "It will provide 2.5B Input and 1B Output Tokens per month",
        "It will provide 30B Input and 12B Output Tokens per year",
        "You can Create and Train your own AI Agent",
        "Retrieving data from AI Agent",
        "Generate API keys for third-party access.",
      ],
      buttonLabel: "Buy Plus Plan",
    },
    {
      type: "custom",
      price: "$",
      title: "Custom Plan",
      info: "Unlimited access to all features.",
      info2: "Billed Annually",
      features: [
        "It will provide custom Input and Output Tokens per month",
        "It will provide custom Input and Output Tokens per year",
        "You can Create and Train your own AI Agent",
        "Retrieving data from AI Agent",
        "Generate API keys for third-party access.",
      ],
      buttonLabel: "Contact Us",
      handleClick: handleContactUs,
    },
  ];

  const filteredPlans =
    statusData && statusData.subscriptionType
      ? plans.filter((plan) => plan.type === statusData.subscriptionType)
      : plans;

  return (
    <CustomLayout>
      {isLoading && (
        <div className="loader-container">
          <Oval
            color="#86d3ff"
            height={50}
            width={50}
            radius="7"
            secondaryColor="#86d3ff"
          />
        </div>
      )}
      {hasFetched && !isLoading && (
        <div className="price">
          <div className="price_cont">
            {filteredPlans.map((plan) => (
              <div className="price_cont_card" key={plan.type}>
                <div className="plan">
                  <div className="inner">
                    <span className="pricing">
                      <span>
                        {plan.price}
                        <small>/ m</small>
                      </span>
                    </span>
                    <p className="title">{plan.title}</p>
                    <p className="info">{plan.info}</p>
                    <p className="info2">{plan.info2}</p>
                    <ul className="features">
                      {plan.features.map((feature, index) => (
                        <li key={index}>
                          <span className="icon">
                            <RightIcon />
                          </span>
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                    <div className="action">
                      <Buttons
                        variant="primary"
                        onClick={() => {
                          if (statusData) {
                            setShowPopup(true);
                          } else {
                            handlePayment(plan.type);
                          }
                        }}
                      >
                        {statusData ? "View Details" : plan.buttonLabel}
                      </Buttons>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {showPopup && (
        <div className="details">
          <div className="details_popup">
            <div className="details_popup_header">
              <h2>Current Plan Details</h2>
            </div>
            <div className="details_popup_body">
              <div className="details_popup_body_top">
                <h2>{statusData?.subscriptionType}</h2>
                <span>Current Plan</span>
              </div>
              <div className="details_popup_body_center">
                <span>
                  Input Tokens: <strong>{statusData?.inputTokenCount}</strong>
                </span>
                <span>
                  Output Tokens: <strong>{statusData?.outputTokenCount}</strong>
                </span>
                <span>
                  Additional Input Tokens:{" "}
                  <strong>{statusData?.additionalInputTokenCount}</strong>
                </span>
                <span>
                  Additional Output Tokens:{" "}
                  <strong>{statusData?.additionalOutputTokenCount}</strong>
                </span>
                <span>
                  Start Date:{" "}
                  <strong>
                    {formatDate(statusData?.subscriptionStartDate)}
                  </strong>
                </span>
                <span>
                  End Date:{" "}
                  <strong>{formatDate(statusData?.subscriptionEndDate)}</strong>
                </span>
              </div>
            </div>
            <div className="details_popup_buttom">
              <button onClick={handleClose}>OK</button>
            </div>
          </div>
        </div>
      )}
    </CustomLayout>
  );
};

export default Subscription;
