import "./App.scss";
import RouterComponent from "./app/RouterComponent/RouterComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserProvider } from "./app/store/SubcriptionStore";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <RouterComponent />
        <ToastContainer theme="colored" position="top-right" autoClose={5000} />
      </UserProvider>
    </div>
  );
}

export default App;
