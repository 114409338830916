import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/Enterprise/Login/Login";
import ForgotPassword from "../pages/Enterprise/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/Enterprise/ResetPassword/ResetPassword";
import Dashboard from "../pages/Enterprise/Dashboard/Dashboard";
import LLM from "../pages/Enterprise/LLM/LLM";
import UserManagement from "../pages/Enterprise/UserManagement/UserManagement";
import CreateLLM from "../pages/Enterprise/CreateLLM/CreateLLM";
import AdminLogin from "../pages/SuperAdmin/AdminLogin/AdminLogin";
import AdminForgotPassword from "../pages/SuperAdmin/AdminForgotPassword/AdminForgotPassword";
import AdminResetPassword from "../pages/SuperAdmin/AdminResetPassword/AdminResetPassword";
import AdminUserManagement from "../pages/SuperAdmin/AdminUserManagement/AdminUserManagement";
import ChangePassword from "../pages/SuperAdmin/ChangePassword/ChangePassword";
import Settings from "../pages/Enterprise/Settings/Settings";
import SignUp from "../pages/Enterprise/SignUp/SignUp";
import EnterpriseUsers from "../pages/SuperAdmin/EnterpriseUsers/EnterpriseUsers";
import UserConfirm from "../pages/Enterprise/UserConfirm/UserConfirm";
import LLMDetails from "../pages/Enterprise/LLMDetails/LLMDetails";
import WidgetLayout from "../pages/Enterprise/WidgetLayout/WidgetLayout";
import ApiKey from "../pages/Enterprise/ApiKey/ApiKey";
import ProtectedRoute from "./ProtectedRoute";
import ApiDocument from "../pages/Enterprise/ApiDocument/ApiDocument";
import Subscription from "../pages/Enterprise/Subscription/Subscription";
import PaymentCancel from "../pages/Enterprise/PaymentCancel/PaymentCancel";
import PaymentSucess from "../pages/Enterprise/PaymentSucess/PaymentSucess";
import PaymentHistory from "../pages/Enterprise/PaymentHistory/PaymentHistory";
import AdminPaymentDetails from "../pages/SuperAdmin/AdminPaymentDetails/AdminPaymentDetails";
import EnterpriseUserLogin from "../pages/EnterpriseUser/EnterpriseUserLogin/EnterpriseUserLogin";
import UserDashboard from "../pages/EnterpriseUser/UserDashboard/UserDashboard";
import UserChatBot from "../pages/EnterpriseUser/UserChatBot/UserChatBot";
import LLMUserDetails from "../pages/Enterprise/LLMUserDetails/LLMUserDetails";
import AllLLMData from "../pages/Enterprise/AllLLMData/AllLLMData";
import EnterpriseUserForgot from "../pages/EnterpriseUser/EnterpriseUserForgot/EnterpriseUserForgot";
import EnterpriseUserReset from "../pages/EnterpriseUser/EnterpriseUserReset/EnterpriseUserReset";
import NewsLetter from "../pages/SuperAdmin/NewsLetter/NewsLetter";
import UserLLM from "../pages/EnterpriseUser/UserLLM/UserLLM";
import CustomPayment from "../pages/SuperAdmin/CustomPayment/CustomPayment";
import UserLLMDetails from "../pages/EnterpriseUser/UserLLMDetails/UserLLMDetails";
import UserApiKey from "../pages/EnterpriseUser/UserApiKey/UserApiKey";
import UserApiKeyDoc from "../pages/EnterpriseUser/UserApiKeyDoc/UserApiKeyDoc";

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        //Enterprise
        <Route path="/" element={<Login />} />
        <Route path="/enterprise-signup" element={<SignUp />} />
        <Route path="/confirm" element={<UserConfirm />} />
        <Route path="/enterprise-forgot" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/enterprise-dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/enterprise-llm" element={<ProtectedRoute><LLM /></ProtectedRoute>} />
        <Route path="/enterprise-user" element={<ProtectedRoute><UserManagement /></ProtectedRoute>} />
        <Route path="/enterprise-createllm" element={<ProtectedRoute><CreateLLM /></ProtectedRoute>} />
        <Route path="/enterprise-settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
        <Route path="/llmdetails/:id" element={<ProtectedRoute><LLMDetails /></ProtectedRoute>} />
        <Route path="/widget/:id" element={<ProtectedRoute><WidgetLayout /></ProtectedRoute>} />
        <Route path="/apikey/:id" element={<ProtectedRoute><ApiKey /></ProtectedRoute>} />
        <Route path="/api-document" element={<ProtectedRoute><ApiDocument /></ProtectedRoute>} />
        <Route path="/subscription" element={<ProtectedRoute><Subscription /></ProtectedRoute>} />
        <Route path="/payment-cancel" element={<ProtectedRoute><PaymentCancel /></ProtectedRoute>} />
        <Route path="/payment-success" element={<ProtectedRoute><PaymentSucess /></ProtectedRoute>} />
        <Route path="/payment-history" element={<ProtectedRoute><PaymentHistory /></ProtectedRoute>} />
        <Route path="/llm-users-data" element={<ProtectedRoute><LLMUserDetails /></ProtectedRoute>} />
        <Route path="/all-llm-data" element={<ProtectedRoute><AllLLMData /></ProtectedRoute>} />


        //Enterprise-User
        <Route path="/enterprise/user/login" element={<EnterpriseUserLogin />} />
        <Route path="/enterprise/user/chatbot/:id" element={<ProtectedRoute><UserChatBot /></ProtectedRoute>} />
        <Route path="/enterprise/user/llms" element={<ProtectedRoute><UserLLM /></ProtectedRoute>} />
        <Route path="/enterprise/user/llm-details/:id" element={<ProtectedRoute><UserLLMDetails /></ProtectedRoute>} />
        <Route path="/enterprise/user/api-key/:id" element={<ProtectedRoute><UserApiKey /></ProtectedRoute>} />
        <Route path="/enterprise/user/api-doc" element={<ProtectedRoute><UserApiKeyDoc /></ProtectedRoute>} />
        <Route path="/enterprise/user/forgot" element={<EnterpriseUserForgot />} />
        <Route path="/enterprise/user/resetpassword" element={<EnterpriseUserReset />} />

        //Super Admin
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin-forgot" element={<AdminForgotPassword />} />
        <Route path="/admin-reset-password" element={<AdminResetPassword />} />
        <Route path="/admin-user" element={<ProtectedRoute><AdminUserManagement /></ProtectedRoute>} />
        <Route path="/admin-enterprise" element={<ProtectedRoute><EnterpriseUsers /></ProtectedRoute>} /> 
        <Route path="/admin-subscription-details" element={<ProtectedRoute><AdminPaymentDetails /></ProtectedRoute>} /> 
        <Route path="/admin-news-letter" element={<ProtectedRoute><NewsLetter /></ProtectedRoute>} /> 
        <Route path="/admin-unsubscribed-users" element={<ProtectedRoute><CustomPayment /></ProtectedRoute>} /> 


      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
