import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import "./PaymentSucess.scss";

const PaymentSucess = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState("");

  const handleDashboardPage = () => {
    navigate("/enterprise-dashboard");
  };
  return (
    <div className="ps">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="ps_container">
          <div className="ps_container_modal">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
            >
              <circle
                class="path circle"
                fill="none"
                stroke="#73AF55"
                stroke-width="6"
                stroke-miterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                class="path check"
                fill="none"
                stroke="#73AF55"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
            {/* <p className="success">{messages}</p> */}
            <div className="pc_container_text">
              <p className="success">Payment Successful!</p>
              <p>Thank you for completing your secure online payment</p>
            </div>
            <div className="ps_container_button">
              <button onClick={handleDashboardPage}>Go Back</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentSucess;
