import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  useTable,
  useRowSelect,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import Icon from "./Icon";
import "./CommonStyle.scss";
import {
  ADMIN_USER_MGMT,
  DELETE_MYAISQUAD_USER,
  UPDATE_MYAISQUAD_ENABLE_USER,
} from "../../utils/apiPath";
import { getApi, postApi } from "../../utils/apiService";
import { debounce } from "lodash";
import FormInputs from "../FormInputs/FormInputs";
import { errorToast, successToast } from "../../services/ToastHelper";
import Switch from "react-switch";
import { IconButton, Tooltip } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import AdminConfirm from "../AdminConfirm/AdminConfirm";
import "./Table.scss";

// const IndeterminateCheckbox = React.forwardRef(
//   ({ indeterminate, ...rest }, ref) => {
//     const defaultRef = React.useRef();
//     const resolvedRef = ref || defaultRef;

//     React.useEffect(() => {
//       resolvedRef.current.indeterminate = indeterminate;
//     }, [resolvedRef, indeterminate]);

//     return (
//       <>
//         <input
//           type="checkbox"
//           ref={resolvedRef}
//           {...rest}
//           className="table-checkbox"
//         />
//       </>
//     );
//   }
// );

const UserTable = () => {
  const [userDetails, setUserDetails] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmData, setConfirmData] = useState(null);

  const updateUserEnableStatus = async (userId, isEnable) => {
    const payload = {
      userId,
      isEnable,
    };
    const { status, message } = await postApi(
      UPDATE_MYAISQUAD_ENABLE_USER,
      payload
    );
    if (status === 200) {
      successToast(message);
      fetchUserDetails(pageIndex, pageSize, searchQuery);
      return true;
    } else {
      errorToast(message);
      return false;
    }
  };

  const handleDelete = async (userId) => {
    setConfirmData({ userId });
    setConfirmAction("delete");
    setConfirmVisible(true);
  };

  const confirmActionHandler = async () => {
    setConfirmVisible(false);
    const { userId, isEnable } = confirmData;

    if (confirmAction === "enable-disable") {
      await updateUserEnableStatus(userId, !isEnable);
    } else if (confirmAction === "delete") {
      await handleDeleteConfirm(userId);
    }
  };

  const handleDeleteConfirm = async (userId) => {
    const payload = { userId };
    const { status, message } = await postApi(DELETE_MYAISQUAD_USER, payload);
    if (status === 200) {
      successToast(message);
      fetchUserDetails(pageIndex, pageSize, searchQuery);
    } else {
      errorToast(message);
    }
  };

  const handleToggle = (data) => {
    const userId = data?.original?.userId;
    const isEnable = data?.original?.isEnable;
    setConfirmData({ userId, isEnable });
    setConfirmAction("enable-disable");
    setConfirmVisible(true);
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: "Sl No.",
        accessor: (row, index) => index + 1 + pageIndex * pageSize,
      },
      {
        Header: "Full Name",
        accessor: "fullName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: ({ value }) => <span>{value ? "Active" : "Inactive"}</span>,
      },
      {
        Header: "Enable/Disable",
        accessor: "isEnable",
        Cell: ({ row }) => (
          <Switch
            onChange={() => handleToggle(row)}
            checked={row.original.isEnable}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
          />
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div className="">
            <Tooltip title="Delete" placement="top">
              <IconButton onClick={() => handleDelete(row.original.userId)}>
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   //   Cell: (row) => {
      //   //     return (
      //   //       <span className="block w-full">
      //   //         <span
      //   //           className={` inline-block px-3 min-w-[90px] text-center mx-auto py-1 rounded-[999px] bg-opacity-25 ${
      //   //             row?.cell?.value === true
      //   //               ? "text-success-500 bg-success-500"
      //   //               : ""
      //   //           }
      //   //           ${
      //   //             row?.cell?.value === false
      //   //               ? "text-danger-500 bg-danger-500"
      //   //               : ""
      //   //           }

      //   //            `}
      //   //         >
      //   //           {row?.cell?.value === true ? "Active" : "In - active"}
      //   //         </span>
      //   //       </span>
      //   //     );
      //   //   },
      // },
      // {
      //   Header: "Action",
      //   accessor: "action",
      //   Cell: (row) => {
      //     return (
      //       <div className="flex space-x-3 rtl:space-x-reverse">
      //         {/* <Tooltip
      //           content="View"
      //           placement="top"
      //           arrow
      //           animation="shift-away"
      //         >
      //           <button className="action-btn" type="button">
      //             <Icon icon="heroicons:eye" />
      //           </button>
      //         </Tooltip>
      //         <Tooltip
      //           content="Edit"
      //           placement="top"
      //           arrow
      //           animation="shift-away"
      //         >
      //           <button className="action-btn" type="button">
      //             <Icon icon="heroicons:pencil-square" />
      //           </button>
      //         </Tooltip> */}
      //         {/* <Tooltip
      //           content="Delete"
      //           placement="top"
      //           arrow
      //           animation="shift-away"
      //           theme="danger"
      //         > */}
      //         <button className="action-btn" type="button">
      //           <Icon icon="heroicons:trash" />
      //         </button>
      //         {/* </Tooltip> */}
      //       </div>
      //     );
      //   },
      // },
    ],
    [pageIndex, pageSize]
  );

  const fetchUserDetails = async (pageIndex, pageSize, searchQuery = "") => {
    const { status, data } = await getApi(ADMIN_USER_MGMT, {
      params: { page: pageIndex, pageSize: pageSize, searchName: searchQuery },
    });
    if (status === 200) {
      setUserDetails(data?.usersData);
      setPageCount(data?.totalPages);
    } else {
      setUserDetails([]);
      setPageCount(0);
    }
    setLoading(false);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { globalFilter },
    gotoPage,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns: COLUMNS,
      data: userDetails,
      manualPagination: true,
      pageCount,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: "selection",
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  useEffect(() => {
    fetchUserDetails(pageIndex, pageSize, searchQuery);
  }, [pageIndex, pageSize, searchQuery]);

  const handleSearchChange = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      setPageIndex(0);
    }, 500),
    []
  );

  return (
    <div className="table">
      <div className="table_container">
        <h4>User Management</h4>
        <div className="table_container_search">
          <input
            type="text"
            placeholder="Search by name..."
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
      </div>
      <div className="table_sec">
        <div className="table_sec_cont">
          <div
            className="table_sec_cont_details"
            style={{ overflow: "hidden" }}
          >
            <table {...getTableProps}>
              <thead>
                {headerGroups?.map((headerGroup, index) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={`header-${index}`}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={`column-${index}`}
                        scope="col"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps}>
                {loading ? (
                  <tr>
                    <td colSpan={COLUMNS.length} className="loading-container">
                      <div className="loading-indicator">Loading...</div>
                    </td>
                  </tr>
                ) : (
                  page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={`row-${index}`}>
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            {...cell.getCellProps()}
                            key={`cell-${cellIndex}`}
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="table_page">
        <div className="table_page_section">
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPageIndex(0);
            }}
          >
            {[10, 25, 50].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
          <span>
            Page{" "}
            <span>
              {pageIndex + 1} of {pageCount}
            </span>
          </span>
        </div>
        <ul>
          <li>
            <button
              style={{
                cursor: pageIndex !== 0 ? "pointer" : "default",
              }}
              onClick={() => setPageIndex(0)}
              disabled={pageIndex === 0}
            >
              <Icon icon="heroicons:chevron-double-left-solid" />
            </button>
          </li>
          <li>
            <button
              style={{
                cursor: pageIndex !== 0 ? "pointer" : "default",
              }}
              onClick={() => setPageIndex(pageIndex - 1)}
              disabled={pageIndex === 0}
            >
              Prev
            </button>
          </li>
          {pageOptions?.map((page) => (
            <li key={page}>
              <button
                style={{
                  backgroundColor: page === pageIndex ? "#2D3748" : "#EDF2F7",
                  color: page === pageIndex ? "#fff" : "#2D3748",
                }}
                onClick={() => setPageIndex(page)}
              >
                {page + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              style={{
                cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
              }}
              onClick={() => setPageIndex(pageIndex + 1)}
              disabled={pageIndex === pageCount - 1}
            >
              Next
            </button>
          </li>
          <li>
            <button
              style={{
                cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
              }}
              onClick={() => setPageIndex(pageCount - 1)}
              disabled={pageIndex === pageCount - 1}
            >
              <Icon icon="heroicons:chevron-double-right-solid" />
            </button>
          </li>
        </ul>
      </div>
      {confirmVisible && (
        <AdminConfirm
          title={`Are you sure you want to ${
            confirmAction === "delete"
              ? "delete this user?"
              : "perform this action?"
          }`}
          onCancel={() => setConfirmVisible(false)}
          onConfirm={confirmActionHandler}
        />
      )}
    </div>
  );
};

export default UserTable;
