import React, { useEffect, useState } from "react";
import "./UserConfirm.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Oval, ThreeDots } from "react-loader-spinner";
import { postApi } from "../../../utils/apiService";
import { ENTERPRISE_VERIFY_ACCOUNT } from "../../../utils/apiPath";

const UserConfirm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState("");
  const [isVerified, setIsVerified] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenFromUrl = searchParams.get("token");

  useEffect(() => {
    if (tokenFromUrl) {
      localStorage.setItem("token", tokenFromUrl);
    }
  }, [tokenFromUrl]);

  const fetchUserConfirm = async () => {
    setIsLoading(true);
    const payload = {
      token: tokenFromUrl,
    };
    const { status, message, error } = await postApi(ENTERPRISE_VERIFY_ACCOUNT, payload);
    setIsLoading(false);
    if (status === 200) {
      setMessages(message)
      setIsVerified(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setMessages(error)
      setIsVerified(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUserConfirm();
  }, []);

  const handleLoginPage = () => {
    navigate("/");
  };
  
  return (
    <div className="uc">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
          <Oval color="#86d3ff" height={50} width={50} radius="7" secondaryColor="#86d3ff"/>
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="uc_container">
          {isVerified === true && (
            <div className="uc_container_modal">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
              >
                <circle
                  class="path circle"
                  fill="none"
                  stroke="#73AF55"
                  stroke-width="6"
                  stroke-miterlimit="10"
                  cx="65.1"
                  cy="65.1"
                  r="62.1"
                />
                <polyline
                  class="path check"
                  fill="none"
                  stroke="#73AF55"
                  stroke-width="6"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                  points="100.2,40.2 51.5,88.8 29.8,67.5 "
                />
              </svg>
              {/* <p className="success">
                Your Email has been Verified Sucessfully..!
              </p> */}
              <p className="success">{messages}</p>
              <div className="uc_container_button">
                <button onClick={handleLoginPage}>Go to Login</button>
              </div>
            </div>
          )}

          {isVerified === false && (
            <div className="uc_container_modal">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
              >
                <circle
                  class="path circle"
                  fill="none"
                  stroke="#D06079"
                  stroke-width="6"
                  stroke-miterlimit="10"
                  cx="65.1"
                  cy="65.1"
                  r="62.1"
                />
                <line
                  class="path line"
                  fill="none"
                  stroke="#D06079"
                  stroke-width="6"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                  x1="34.4"
                  y1="37.9"
                  x2="95.8"
                  y2="92.3"
                />
                <line
                  class="path line"
                  fill="none"
                  stroke="#D06079"
                  stroke-width="6"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                  x1="95.8"
                  y1="38"
                  x2="34.4"
                  y2="92.2"
                />
              </svg>
              <div className="uc_container_text">
                <p className="error">{messages}</p>
                <div className="uc_container_button">
                  <button onClick={handleLoginPage}>Go to Login</button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserConfirm;
