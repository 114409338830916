import React, { useEffect, useRef, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import "./AdminLayout.scss";
import Logo from "../../Assets/Images/logo.png";
import UserImg from "../../Assets/Images/headeruser.png";
import { BiLogOut } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { postApi } from "../../utils/apiService";
import { ADMIN_LOGOUT } from "../../utils/apiPath";
import { errorToast, successToast } from "../../services/ToastHelper";
import { Oval } from "react-loader-spinner";
import { SiEnterprisedb } from "react-icons/si";
import Buttons from "../Buttons/Buttons";
import { MdOutlinePayment } from "react-icons/md";
import { SlEnvolopeLetter } from "react-icons/sl";
import { FaUsers } from "react-icons/fa";

const { Header, Content, Sider } = Layout;
const items = [
  {
    key: "1",
    icon: <UserOutlined />,
    label: "User Management",
    path: "/admin-user",
  },
  {
    key: "2",
    icon: <SiEnterprisedb />,
    label: "Enterprise Users",
    path: "/admin-enterprise",
  },
  {
    key: "3",
    icon: <MdOutlinePayment />,
    label: "Subscription Details",
    path: "/admin-subscription-details",
  },
  {
    key: "4",
    icon: <SlEnvolopeLetter />,
    label: "Newsletter",
    path: "/admin-news-letter",
  },
  {
    key: "5",
    icon: <FaUsers />,
    label: "UnSubscribed Users",
    path: "/admin-unsubscribed-users",
  },
];

const AdminLayout = ({ children }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const aToken = localStorage.getItem("accessToken");
  const rToken = localStorage.getItem("refreshToken");

  const getSelectedKey = () => {
    const currentPath = location.pathname;
    const currentItem = items.find((item) => item.path === currentPath);
    return currentItem ? currentItem.key : "1";
  };
  const [selectedKey, setSelectedKey] = useState(getSelectedKey);
  useEffect(() => {
    setSelectedKey(getSelectedKey());
  }, [location.pathname]);

  const handleOpenDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDashboard = () => {
    navigate("/admin-user");
  };

  const handleAdminLogout = async () => {
    setIsLoading(true);
    const payload = {
      accessToken: aToken,
      refreshToken: rToken,
    };
    const { status, message } = await postApi(ADMIN_LOGOUT, payload);
    if (status === 200) {
      const role = localStorage.getItem("userRole");
      localStorage.clear();
      localStorage.setItem("userRole", role);
      navigate("/admin-login");
      successToast(message);
      setIsLoading(false);
    } else {
      errorToast(message);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setDropdownVisible(false);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}
      <Sider
        className="sidebar"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
        }}
      >
        <div className="sidebar_logo" onClick={handleDashboard}>
          <img src={Logo} alt="logo" />
          <h3>Admin Portal</h3>
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[selectedKey]}
          items={items.map(({ key, icon, label, path }) => ({
            key,
            icon,
            label,
            onClick: () => {
              navigate(path);
            },
          }))}
        />
      </Sider>
      <Layout>
        <Header
          className="header"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div className="header_sec">
            <div className="header_sec_logout">
              <img src={UserImg} alt="user" onClick={handleOpenDropdown} />
              {dropdownVisible && (
                <div className="custom-dropdown" ref={dropdownRef}>
                  {/* <BiLogOut />
                  <span onClick={handleAdminLogout}>Logout</span> */}
                  <h3>Are you sure you want to Logout?</h3>
                  <div className="custom-dropdown1_buttons">
                    <Buttons variant="secondary" onClick={handleCancel}>
                      No
                    </Buttons>
                    <Buttons variant="primary" onClick={handleAdminLogout}>
                      Yes
                    </Buttons>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Header>
        <Content className="content">
          <div className="content_center">{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default AdminLayout;
