import React from 'react'
import CustomLayout from '../../../Components/CustomLayout/CustomLayout'

const Settings = () => {
  return (
    <CustomLayout>
        Settings
    </CustomLayout>
  )
}

export default Settings
