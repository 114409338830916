import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const role = localStorage.getItem("userRole");

  useEffect(() => {
    if (!token) {
      if (role === "enterprise") {
        navigate("/");
      } else if (role === "admin") {
        navigate("/admin-login");
      } else if (role === "user") {
        navigate("/enterprise/user/login");
      } else {
        window.location.href = "https://myaisquad.com/";
      }
    }
  }, [token, role, navigate]);

  return children;
};

export default ProtectedRoute;
