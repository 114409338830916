import React, { useState } from "react";
import "./AdminLogin.scss";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import Buttons from "../../../Components/Buttons/Buttons";
import { errorToast, successToast } from "../../../services/ToastHelper";
import { ADMIN_LOGIN_DETAILS } from "../../../utils/apiPath";
import { postApi } from "../../../utils/apiService";

const initialValues = {
  email: "",
  password: "",
};

const AdminLogin = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (name, value) => {

    setLogin({
      ...login,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.email) {
      errObj.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(login.email)) {
      errObj.email = "Enter Valid Email Id";
    } else {
      errObj.email = "";
    }

    if (!login.password) {
      errObj.password = "This field is required";
    } else if (login.password.length < 8) {
      errObj.password = "Password must be at least 8 characters";
    } else {
      errObj.password = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const valid = Object.values(errObj).every((x) => x === "" || x === null);
    return valid;
  };

  const handleLogin = async () => {
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        email: login.email,
        password: login.password,
      };
      const { status, data, message } = await postApi(ADMIN_LOGIN_DETAILS, payload);
      if (status === 200) {
        localStorage.setItem("userRole", "admin"); 
        localStorage.setItem("accessToken", data?.accessToken);
        localStorage.setItem("refreshToken", data?.refreshToken);
        setIsLoading(false);
        navigate("/admin-user");
        successToast(message);
      } else {
        errorToast(message);
        setIsLoading(false);
      }
    }
  };

  const handleForgotPassword = () => {
    navigate("/admin-forgot");
  };

  return (
    <div className="adminlogin">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
          <Oval color="#86d3ff" height={50} width={50} radius="7" secondaryColor="#86d3ff"/>
          </div>
        </div>
      )}
      <div className="adminlogin_cont">
        <div className="adminlogin_cont_section">
          <div className="adminlogin_cont_section_header">
            <h3>Admin Sign In</h3>
          </div>
          <div className="adminlogin_cont_section_inputs">
            <div className="adminlogin_cont_section_inputs_data">
              <FormInputs
                title={"Enter Email"}
                type={"text"}
                placeholder={"Enter Your Email"}
                name="email"
                icon="icon1"
                value={login.email}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div className="adminlogin_cont_section_inputs_data">
              <FormInputs
                title={"Password"}
                type={"password"}
                placeholder={"********"}
                name="password"
                icon="icon2"
                value={login.password}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
              {errors.password && (
                <span className="error">{errors.password}</span>
              )}
            </div>
          </div>
          <div className="adminlogin_cont_section_buttons">
            <Buttons variant="primary" onClick={handleLogin}>
              Login
            </Buttons>
          </div>
          <div
            className="adminlogin_cont_section_forgot"
            
          >
            <p onClick={handleForgotPassword}>Forgot Password?</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
