import React, { useState } from "react";
import "./CreateLLM.scss";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import Buttons from "../../../Components/Buttons/Buttons";
import { postApi } from "../../../utils/apiService";
import { errorToast, successToast } from "../../../services/ToastHelper";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { ADD_NEW_LLM } from "../../../utils/apiPath";
import { Slider } from "antd";

const initialvalues = {
  llmName: "",
  role: "",
  specialization: "",
  description: "",
  temperature: 0.6,
};

const CreateLLM = () => {
  const [userRole, setUserRole] = useState(initialvalues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (name, value) => {
    setUserRole({
      ...userRole,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialvalues };

    if (!userRole.llmName) {
      errObj.llmName = "AI Agent Name is Required";
    } else {
      errObj.llmName = "";
    }

    if (!userRole.role) {
      errObj.role = "Role is Required";
    } else {
      errObj.role = "";
    }

    if (!userRole.specialization) {
      errObj.specialization = "Specialization is Required";
    } else {
      errObj.specialization = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleSliderChange = (value) => {
    setUserRole((prevState) => ({
      ...prevState,
      temperature: value,
    }));
  };

  const handleSubmitLLM = async () => {
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        modelName: userRole.llmName,
        role: userRole.role,
        specialization: userRole.specialization,
        description: userRole.description,
        temperature: userRole.temperature,
      };
      const { status, message } = await postApi(ADD_NEW_LLM, payload);
      if (status === 200) {
        setIsLoading(false);
        successToast(message);
        navigate("/enterprise-llm");
      } else {
        setIsLoading(false);
        errorToast(message);
      }
    }
  };

  const handleLLMPage = () => {
    navigate("/enterprise-llm");
  };

  return (
    <CustomLayout>
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}
      <div className="form">
        <div className="form_cont">
          <div className="form_cont_header">
            <h2>Configure AI Agent</h2>
          </div>
          <div className="form_sec">
            <div className="form_sec_inputs">
              <FormInputs
                title={"AI Agent Name*"}
                type={"text"}
                placeholder={"Enter AI Agent Name"}
                onChange={handleChange}
                name="llmName"
                value={userRole.llmName}
              />
              <div>
                {errors.llmName && (
                  <span className="error">{errors.llmName}</span>
                )}
              </div>
            </div>
            <div className="form_sec_inputs">
              <FormInputs
                title={"Role*"}
                type={"text"}
                placeholder={"Enter Your Role"}
                onChange={handleChange}
                name="role"
                value={userRole.role}
              />
              <div>
                {errors.role && <span className="error">{errors.role}</span>}
              </div>
            </div>
            <div className="form_sec_inputs">
              <FormInputs
                title={"Specialization*"}
                type={"text"}
                placeholder={"Enter Your Specialization"}
                onChange={handleChange}
                name="specialization"
                value={userRole.specialization}
              />
              <div>
                {errors.specialization && (
                  <span className="error">{errors.specialization}</span>
                )}
              </div>
            </div>
            <div className="form_sec_inputs">
              <FormInputs
                title={"Description"}
                type={"text"}
                placeholder={"Enter Your Description"}
                onChange={handleChange}
                name="description"
                value={userRole.description}
                maxLength={200}
              />
            </div>
            <div className="form_sec_inputs">
              <div className="form_sec_inputs_slider">
                <label className="form_sec_inputs_sliderlabel left">
                  Imaginary
                </label>
                <label className="form_sec_inputs_slider right">Factual</label>
              </div>
              <Slider
                min={0.1}
                max={1}
                step={0.1}
                value={userRole.temperature}
                onChange={handleSliderChange}
                marks={{
                  0.1: "0.1",
                  1: "1",
                }}
              />
            </div>
          </div>
          <div className="form_button">
            <Buttons variant="secondary" onClick={handleLLMPage}>
              Cancel
            </Buttons>
            <Buttons variant="primary" onClick={handleSubmitLLM}>
              Submit
            </Buttons>
          </div>
        </div>
      </div>
    </CustomLayout>
  );
};

export default CreateLLM;
