import React, { useEffect, useRef, useState } from "react";
import { CodeOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import "./CustomLayout.scss";
import Logo from "../../Assets/Images/logo.png";
import UserImg from "../../Assets/Images/headeruser.png";
import { BiLogOut } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlinePriceChange } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { ENTERPRISE_LOGOUT, GET_PROFILE_DATA } from "../../utils/apiPath";
import { getApi, postApi } from "../../utils/apiService";
import { errorToast, successToast } from "../../services/ToastHelper";
import { Oval } from "react-loader-spinner";
import Buttons from "../Buttons/Buttons";
import { RiSecurePaymentLine } from "react-icons/ri";
import { SiEnterprisedb } from "react-icons/si";
import EditProfileModal from "../EditProfileModal/EditProfileModal";
import { FaEdit } from "react-icons/fa";

const { Header, Content, Sider } = Layout;

const items = [
  {
    key: "1",
    icon: <SiEnterprisedb />,
    label: "Dashboard",
    path: "/enterprise-dashboard",
  },
  {
    key: "2",
    icon: <MdOutlinePriceChange />,
    label: "Subscription",
    path: "/subscription",
  },
  {
    key: "3",
    icon: <CodeOutlined />,
    label: "AI Agent",
    path: "/enterprise-llm",
  },
  {
    key: "4",
    icon: <RiSecurePaymentLine />,
    label: "Payment History",
    path: "/payment-history",
  },
  {
    key: "5",
    icon: <UserOutlined />,
    label: "User Management",
    path: "/llm-users-data",
  },
];

const CustomLayout = ({ children }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [profileForm, setProfileForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const epAccessToken = localStorage.getItem("accessToken");
  const epRefreshToken = localStorage.getItem("refreshToken");

  const getSelectedKey = () => {
    const currentPath = location.pathname;
    const currentItem = items.find((item) => item.path === currentPath);
    return currentItem ? currentItem.key : null;
  };
  const [selectedKey, setSelectedKey] = useState(getSelectedKey);

  const fetchProfileData = async () => {
    setIsLoading(true);
    const { status, data } = await getApi(GET_PROFILE_DATA);
    if (status === 200) {
      setProfileData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  useEffect(() => {
    setSelectedKey(getSelectedKey());
  }, [location.pathname]);

  const handleOpenDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDashboard = () => {
    navigate("/enterprise-dashboard");
  };

  const handleForm = () => {
    setProfileForm(true);
  };

  const handleEnterpriseLogout = async () => {
    setIsLoading(true);
    const payload = {
      accessToken: epAccessToken,
      refreshToken: epRefreshToken,
    };
    const { status, message } = await postApi(ENTERPRISE_LOGOUT, payload);
    if (status === 200) {
      const role = localStorage.getItem("userRole");
      localStorage.clear();
      localStorage.setItem("userRole", role);
      navigate("/");
      successToast(message);
      setIsLoading(false);
    } else {
      errorToast(message);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setDropdownVisible(false);
    setProfileForm(false);
  };

  const handleCloseModal = () => {
    setProfileForm(false);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}
      <Sider
        className="sidebar"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="sidebar_logoa" onClick={handleDashboard}>
          <img src={profileData.profileImage || Logo} alt="logo" />
          <h3>
            {profileData.title || "Enterprise"} <FaEdit onClick={handleForm} style={{marginLeft:"5px", marginTop:"3px"}} />
          </h3>
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[selectedKey]}
          items={items.map(({ key, icon, label, path }) => ({
            key,
            icon,
            label,
            onClick: () => {
              navigate(path);
            },
          }))}
        />
      </Sider>
      <Layout>
        <Header
          className="header"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div className="header_sec">
            <div className="header_sec_logout">
              <img src={UserImg} alt="user" onClick={handleOpenDropdown} />
              {dropdownVisible && (
                <div className="custom-dropdown1" ref={dropdownRef}>
                  <h3>Are you sure you want to Logout?</h3>
                  <div className="custom-dropdown1_buttons">
                    <Buttons variant="secondary" onClick={handleCancel}>
                      No
                    </Buttons>
                    <Buttons variant="primary" onClick={handleEnterpriseLogout}>
                      Yes
                    </Buttons>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Header>
        <EditProfileModal
          visible={profileForm}
          onClose={handleCloseModal}
          onSuccess={fetchProfileData}
        />
        <Content className="content">
          <div className="content_center">{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default CustomLayout;
