import React, { useState } from "react";
import "../EnterpriseUserLogin/EnterpriseUserLogin.scss";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import Buttons from "../../../Components/Buttons/Buttons";
import { errorToast, successToast } from "../../../services/ToastHelper";
import { postApi } from "../../../utils/apiService";
import { LLM_USER_REQUEST_RESETPASSWORD } from "../../../utils/apiPath";

const initialValues = {
  email: "",
};

const EnterpriseUserForgot = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (name, value) => {

    setLogin({
      ...login,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.email) {
      errObj.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(login.email)) {
      errObj.email = "Enter Valid Email Id";
    } else {
      errObj.email = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleLogin = async () => {
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        userEmail: login.email,
      };
      const { status, message } = await postApi(
        LLM_USER_REQUEST_RESETPASSWORD,
        payload
      );
      if (status === 200) {
        setIsLoading(false);
        successToast(message);
      } else {
        setIsLoading(false);
        errorToast(message);
      }
    }
  };

//   const handleUserLogin = () => {
//     navigate("/enterprise/user/login");
//   };

  return (
    <div className="ul">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}
      <div className="ul_cont">
        <div className="ul_cont_section">
          <div className="ul_cont_section_header">
            <h3>Verify Email</h3>
          </div>
          <div className="ul_cont_section_inputs">
            <div className="ul_cont_section_inputs_data">
              <FormInputs
                title={"Enter Email"}
                type={"text"}
                placeholder={"Enter Your Email"}
                name="email"
                icon="icon1"
                value={login.email}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
          </div>
          {/* <div className="ul_cont_section_forgot">
            <p onClick={handleUserLogin}>Back to Login</p>
          </div> */}
          <div className="ul_cont_section_buttons">
            <Buttons variant="primary" onClick={handleLogin}>
              Send Request
            </Buttons>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseUserForgot;
