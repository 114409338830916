import React from "react";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";

const UserManagement = () => {
  return (
    <div>
      <CustomLayout>
        <div>

        </div>
      </CustomLayout>
    </div>
  );
};

export default UserManagement;
