import React, { useEffect, useRef, useState } from "react";
import "./UserChatBot.scss";
import chatbotmsg from "../../../Assets/Images/chatbotmsg.png";
import chatbotsend from "../../../Assets/Images/chatbotsend.png";
import loader from "../../../Assets/Images/loading.png";
import { postApi } from "../../../utils/apiService";
import { errorToast } from "../../../services/ToastHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LLM_USER_CHAT_BOT } from "../../../utils/apiPath";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { BackArrow } from "../../../Assets/icons";
import UserLayout from "../../../Components/UserLayout/UserLayout";

const UserChatBot = () => {
  const { id } = useParams();
  const location = useLocation();
  const llmData = location.state?.llmData;
  const [isMessage, setIsMessage] = useState("");
  const [isResponse, setIsResponse] = useState("");
  const [chatData, setChatData] = useState([]);
  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const navigate = useNavigate();

  console.log("llmData:", llmData);
  console.log(id)

  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [chatData]);

  useEffect(() => {
    if (!isResponse) {
      textareaRef.current.focus();
    }
  }, [isResponse]);

  const handleChatResponse = async () => {
    setIsResponse(true);
    const payload = {
      question: isMessage,
      llmId: id,
    };
    const { status, data, message } = await postApi(LLM_USER_CHAT_BOT, payload);
    if (status === 200) {
      const formattedData = parseResponse(data?.answer);
      setChatData([
        ...chatData,
        { question: data?.question, result: formattedData },
      ]);
      setIsMessage("");
      setIsResponse(false);
    } else {
      errorToast(message);
      setIsResponse(false);
    }
  };

  const parseResponse = (response) => {
    const codeRegex = /```(\w+)?\s*([\s\S]*?)\s*```/g;
    const matches = [...response?.matchAll(codeRegex)];
    const parts = response?.split(codeRegex);

    const wordsToFilter = [
      "jsx",
      "html",
      "javascript",
      "css",
      "scss",
      "python",
      "java",
      "bash",
    ];

    const parsedContent = parts?.map((part, index) => {
      if (index % 3 === 2) {
        const language =
          matches[Math.floor(index / 3)][1]?.toLowerCase() || "text";
        const filteredContent = wordsToFilter?.reduce((content, word) => {
          const wordRegex = new RegExp(`^\\s*${word}\\s*`, "i");
          return content?.replace(wordRegex, "");
        }, part.trim());
        return {
          type: "code",
          language: language,
          content: filteredContent,
        };
      }

      const paragraphs = part
        ?.split("\n\n")
        .map((p) => p.split("\n").map((line) => line.trim()));
      return {
        type: "text",
        content: paragraphs,
      };
    });

    return parsedContent.map((item) => {
      if (item.type === "text") {
        item.content = item.content.map((paragraph) => {
          return paragraph.map((line) => {
            if (line.startsWith("###")) {
              return { type: "h3", content: line.replace("###", "").trim() };
            }
            if (line.startsWith("**") && line.endsWith("**")) {
              return {
                type: "h2",
                content: line.replace(/\*\*/g, "").trim(),
              };
            }
            return { type: "p", content: line };
          });
        });
      }
      return item;
    });
  };

  const renderChatContent = (content) => {
    return content?.map((part, index) => {
      if (part.type === "code") {
        return (
          <SyntaxHighlighter
            language={part?.language}
            style={vscDarkPlus}
            key={index}
          >
            {part?.content}
          </SyntaxHighlighter>
        );
      }

      return (
        <div key={index}>
          {part?.content?.map((paragraph, pIndex) => (
            <div key={pIndex}>
              {paragraph.map((line, lIndex) => {
                if (line.type === "h3") {
                  return <h3 key={lIndex}>{line.content}</h3>;
                }
                if (line.type === "h2") {
                  return <h2 key={lIndex}>{line.content}</h2>;
                }
                if (line.type === "p") {
                  return <p key={lIndex}>{line.content}</p>;
                }
                return null;
              })}
            </div>
          ))}
        </div>
      );
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleChatResponse();
    } else if (e.key === "Enter" && e.shiftKey) {
      setIsMessage(isMessage + "\n");
    }
  };

  const handleLLMDetails = () => {
    navigate(-1);
  };

  return (
    <UserLayout>
      <div className="chatbot">
        <div className="chatbot_header">
          <div className="chatbot_header_left">
            <span onClick={handleLLMDetails}>
              <BackArrow />
            </span>
          </div>
          <div className="chatbot_header_right">
            <h3>{llmData?.llmName || "AI Agent ChatBot"}</h3>
          </div>
          <div></div>
        </div>
        <div className="chatbot_main">
          <div className="chatbot_main_container">
            <div className="chatbot_main_suggestions" ref={chatContainerRef}>
              {chatData?.map((res, index) => (
                <div key={index} className="tempdata">
                  <div className="chatbot_main_suggestions_box">
                    <h5>{res.question}</h5>
                    {renderChatContent(res.result)}
                  </div>
                </div>
              ))}
            </div>
            <div className="chatbot_main_message">
              <div className="chatbotmsgimg">
                <img src={chatbotmsg} alt="chatbotmsg" />
              </div>
              <textarea
                ref={textareaRef}
                type="text"
                placeholder="Send a message"
                value={isMessage}
                disabled={isResponse === true}
                onChange={(e) => setIsMessage(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <div className="chatbotsendimg">
                {isResponse === true ? (
                  <img className="loader" src={loader} alt="Loading" />
                ) : (
                  <img
                    className="send"
                    src={chatbotsend}
                    alt="chatbotsend"
                    onClick={handleChatResponse}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default UserChatBot;
