import React, { useState } from "react";
import axios from "axios";
import "./EditProfileModal.scss";
import Buttons from "../Buttons/Buttons";
import { errorToast, successToast } from "../../services/ToastHelper";

const EditProfileModal = ({ visible, onClose, onSuccess }) => {
  const [companyName, setCompanyName] = useState("");
  const [logo, setLogo] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLogoChange = (e) => {
    setLogo(e.target.files[0]);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!companyName.trim()) newErrors.companyName = "Company Name is required";
    if (!logo) newErrors.logo = "Logo image is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append("title", companyName);
    formData.append("profileImage", logo);

    try {
      setIsSubmitting(true);
      const token = localStorage.getItem("accessToken");

      const response = await axios.post(
        "https://enterprise-api.myaisquad.com/api/v1/enterprise/profile/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        successToast("Profile updated successfully");
        onSuccess();
        onClose();
      } else {
        errorToast("Failed to update profile");
      }
    } catch (error) {
      console.error("Error uploading profile:", error);
      errorToast("An error occurred while updating the profile");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!visible) return null;

  return (
    <div className="edit-profile-modal">
      <div className="modal-content">
        <h2>Edit Profile</h2>
        <label>Company Name</label>
        <input
          type="text"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        {errors.companyName && (
          <span className="error">{errors.companyName}</span>
        )}

        <label>Logo Image</label>
        <input type="file" onChange={handleLogoChange} />
        {errors.logo && <span className="error">{errors.logo}</span>}

        <div className="modal-buttons">
          <Buttons
            variant="secondary"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </Buttons>
          <Buttons
            variant="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Save
          </Buttons>
        </div>
      </div>
    </div>
  );
};

export default EditProfileModal;
