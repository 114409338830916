import React, { useEffect, useRef, useState } from "react";
import "./LLMDetails.scss";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteApi, getApi, postApi, putApi } from "../../../utils/apiService";
import { Oval } from "react-loader-spinner";
import { errorToast, successToast } from "../../../services/ToastHelper";
import Buttons from "../../../Components/Buttons/Buttons";
import uploadIcon from "../../../Assets/Images/uploadFile.png";
import {
  ADD_LLM_USER,
  ADD_SCRAPE_LINK,
  DELETE_FILE,
  DELETE_SCRAPE_LINK,
  GET_ALL_FILE_DATA,
  GET_SCRAPE_LINK,
  UPDATE_LLM_DETAILS,
} from "../../../utils/apiPath";
import axios from "axios";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { BackArrow } from "../../../Assets/icons";
import { IconButton, Tooltip } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AdminConfirm from "../../../Components/AdminConfirm/AdminConfirm";
import { Slider } from "antd";

const initialvalues = {
  link: "",
};

const userInitialValues = {
  userName: "",
  userEmail: "",
  // userPassword: "",
};

const LLMDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { storeData } = state || {};
  const [llmDetails, setLlmDetails] = useState(storeData || {});
  const [userInputs, setUserInputs] = useState(userInitialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [file, setFile] = useState(null);
  const [uploadLink, setUploadLink] = useState(initialvalues);
  const [errors, setErrors] = useState({});
  const [linkNames, setLinkNames] = useState([]);
  const [linkCopied, setLinkCopied] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [userPopUp, setUserPopUp] = useState(false);
  const fileInputRef = useRef(null);

  const handleUserChange = (name, value) => {
    setUserInputs({
      ...userInputs,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleLinkChange = (name, value) => {
    setUploadLink({
      ...uploadLink,
      [name]: value,
    });
    // setErrors({
    //   ...uploadLink,
    //   [name]: "",
    // });
  };

  const validateFields = () => {
    let errObj = { ...initialvalues };

    if (!uploadLink.link) {
      errObj.link = "Add link";
    } else {
      errObj.link = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleLinkCancel = () => {
    setUploadLink({
      ...initialvalues,
    });
  };

  const handleUploadLink = async () => {
    setIsLoading(true);
    if (validateFields()) {
      const payload = {
        llmId: id,
        url: uploadLink.link,
      };
      const { status, message } = await postApi(ADD_SCRAPE_LINK, payload);
      if (status === 200) {
        successToast(message);
        setIsLoading(false);
        setUploadLink({
          ...initialvalues,
        });
        fetchUserLinkStatus();
      } else {
        errorToast(message);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!storeData) {
      errorToast("LLM data not found!");
      navigate("/enterprise-llm");
    }
  }, [storeData, navigate]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    const payload = {
      llmId: id,
      modelName: llmDetails.llmName,
      role: llmDetails.role,
      specialization: llmDetails.specialization,
      description: llmDetails.description,
      temperature: llmDetails.temperature,
    };
    const { status, message } = await putApi(UPDATE_LLM_DETAILS, payload);
    if (status === 200) {
      successToast(message);
      setIsEditing(false);
      setIsLoading(false);
    } else {
      errorToast(message);
      setIsEditing(false);
      setIsLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setLlmDetails({
      ...llmDetails,
      [name]: value,
    });
  };

  const handleSliderChange = (value) => {
    setLlmDetails((prevState) => ({
      ...prevState,
      temperature: value,
    }));
  };

  const handleChooseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected for upload");
      return;
    }
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("fileUpload", file);
      formData.append("llmId", id);
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios.post(
        "https://enterprise-api.myaisquad.com/api/v1/enterprise/llm/uploadfile",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      setIsLoading(false);
      successToast(response.message);
      setFile(null);
      fetchUserFiles();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchUserFiles = async () => {
    const { status, data } = await getApi(GET_ALL_FILE_DATA, {
      params: { llmId: id },
    });
    if (status === 200) {
      setFileNames(data);
    } else {
      setFileNames([]);
    }
  };

  useEffect(() => {
    fetchUserFiles();
  }, [id]);

  const handleDeleteFile = (fileId) => {
    setItemToDelete({ type: "file", id: fileId });
    setConfirmVisible(true);
  };

  const handleDeleteLink = (linkId) => {
    setItemToDelete({ type: "link", id: linkId });
    setConfirmVisible(true);
  };

  const confirmActionHandler = async () => {
    setIsLoading(true);
    if (itemToDelete) {
      if (itemToDelete.type === "file") {
        const payload = { fileId: itemToDelete.id, llmId: id };
        const data = {
          headers: { "Content-Type": "application/json" },
          data: payload,
        };
        const { status, message } = await deleteApi(DELETE_FILE, data);
        if (status === 200) {
          successToast(message);
          fetchUserFiles();
        } else {
          errorToast(message);
        }
      } else if (itemToDelete.type === "link") {
        const payload = { scrapedId: itemToDelete.id, llmId: id };
        const data = {
          headers: { "Content-Type": "application/json" },
          data: payload,
        };
        const { status, message } = await deleteApi(DELETE_SCRAPE_LINK, data);
        if (status === 200) {
          successToast(message);
          fetchUserLinkStatus();
        } else {
          errorToast(message);
        }
      }
      setIsLoading(false);
      setItemToDelete(null);
      setConfirmVisible(false);
    }
  };

  const handleApiKeyPage = () => {
    navigate(`/apikey/${id}`);
  };

  const handleChatBotPage = () => {
    navigate(`/widget/${id}`, { state: { llmName: llmDetails.llmName } });
  };

  const handleClearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setFile(null);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  const fetchUserLinkStatus = async () => {
    const { status, data } = await getApi(GET_SCRAPE_LINK, {
      params: { llmId: id },
    });
    if (status === 200 && data) {
      setLinkNames(data);
    } else {
      setLinkNames([]);
    }
  };

  useEffect(() => {
    fetchUserLinkStatus();
  }, []);

  const handleCopyLink = (scrapedLink) => {
    navigator.clipboard
      .writeText(scrapedLink)
      .then(() => {
        console.log("Link copied to clipboard");
        setLinkCopied(true);
        setTimeout(() => {
          setLinkCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
  };

  const handleLLMPage = () => {
    navigate("/enterprise-llm");
  };

  const handleUserPopup = () => {
    setUserPopUp(true);
  };

  const validateUserFields = () => {
    let errObj = { ...userInitialValues };

    if (!userInputs.userName) {
      errObj.userName = "This field is required";
    } else {
      errObj.userName = "";
    }

    if (!userInputs.userEmail) {
      errObj.userEmail = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userInputs.userEmail)) {
      errObj.userEmail = "Enter Valid Email Id";
    } else {
      errObj.userEmail = "";
    }

    // if (!userInputs.userPassword) {
    //   errObj.userPassword = "This field is required";
    // } else if (userInputs.userPassword.length < 8) {
    //   errObj.userPassword = "Password must be at least 8 characters";
    // } else {
    //   errObj.userPassword = "";
    // }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const hanldeUserCreate = async () => {
    if (validateUserFields()) {
      setIsLoading(true);
      const payload = {
        llmId: id,
        userName: userInputs.userName,
        userEmail: userInputs.userEmail,
        // userPassword: userInputs.userPassword,
      };

      const { status, message } = await postApi(ADD_LLM_USER, payload);
      if (status === 200) {
        successToast(message);
        setUserPopUp(false);
        setIsLoading(false);
        setUserInputs(userInitialValues);
      } else {
        errorToast(message);
        setUserPopUp(false);
        setIsLoading(false);
      }
    }
  };

  const hanldeUserCancel = () => {
    setUserPopUp(false);
    setUserInputs(userInitialValues);
  };

  return (
    <CustomLayout>
      {isLoading && (
        <div className="loader-container">
          <Oval
            color="#86d3ff"
            height={50}
            width={50}
            radius="7"
            secondaryColor="#86d3ff"
          />
        </div>
      )}
      <div className="llmd">
        <div className="llmd_header">
          <div className="llmd_header_left" onClick={handleLLMPage}>
            <BackArrow />
          </div>
          <div className="llmd_header_right">
            {/* <Buttons variant="primary" onClick={handleUserPopup}>
              Create User
            </Buttons> */}
            <Buttons variant="primary" onClick={handleChatBotPage}>
              Test Chat Bot
            </Buttons>
            <Buttons variant="primary" onClick={handleApiKeyPage}>
              API Key
            </Buttons>
          </div>
        </div>
        <div className="llmd_cont">
          <div className="llmd_sec">
            <div className="llmd_sec_header">
              <h2>AI Agent Details</h2>
              {!isEditing && (
                <Buttons variant="primary" onClick={handleEditClick}>
                  Edit AI Agent
                </Buttons>
              )}
              {isEditing && (
                <div className="llmd_sec_header_buttons">
                  <Buttons variant="secondary" onClick={handleEditCancel}>
                    Cancel
                  </Buttons>
                  <Buttons variant="primary" onClick={handleSaveClick}>
                    Update AI Agent
                  </Buttons>
                </div>
              )}
            </div>
            <div className="llmd_sec_top">
              <div className="llmd_sec_top_inputs">
                <FormInputs
                  title={"AI Agent Name"}
                  type={"text"}
                  value={llmDetails.llmName || ""}
                  name="llmName"
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <FormInputs
                  title={"Role"}
                  type={"text"}
                  value={llmDetails.role || ""}
                  name="role"
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <FormInputs
                  title={"Specialization"}
                  type={"text"}
                  value={llmDetails.specialization || ""}
                  name="specialization"
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <FormInputs
                  title={"Description"}
                  type={"text"}
                  value={llmDetails.description || ""}
                  name="description"
                  onChange={handleChange}
                  disabled={!isEditing}
                  maxLength={200}
                />
                <div className="llmd_sec_top">
                  <div className="llmd_sec_top_slider">
                    <label className="llmd_sec_top_sliderlabel left">
                      Imaginary
                    </label>
                    <label className="llmd_sec_top_slider right">Factual</label>
                  </div>
                  <Slider
                    min={0.1}
                    max={1}
                    step={0.1}
                    value={llmDetails.temperature}
                    onChange={handleSliderChange}
                    marks={{
                      0.1: "0.1",
                      1: "1",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="llmd_bottom">
            <div className="llmd_upload">
              <div className="llmd_upload_left">
                <div className="llmd_upload_left_sec">
                  <div className="llmd_upload_left_sec_file">
                    <div
                      className="llmd_upload_left_sec_file_choose"
                      onClick={handleChooseClick}
                    >
                      {file ? (
                        <p>{file.name}</p>
                      ) : (
                        <>
                          <img src={uploadIcon} alt="uploadIcon" />
                          <div className="text">
                            <h3>Click Here To Upload File</h3>
                            <span>(.pdf/.docx/.txt only)</span>
                          </div>
                        </>
                      )}
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="file-input"
                      onChange={(e) => handleFileChange(e)}
                      style={{ display: "none" }}
                    />
                  </div>
                  <div className="llmd_upload_left_sec_button">
                    <Buttons variant="secondary" onClick={handleClearFileInput}>
                      Cancel
                    </Buttons>
                    <Buttons variant="primary" onClick={handleUpload}>
                      Submit
                    </Buttons>
                  </div>
                </div>
              </div>
              <div className="llmd_upload_right">
                <div className="llmd_upload_right_content">
                  {fileNames?.map((file, index) => (
                    <div
                      key={index}
                      className="llmd_upload_right_content_print"
                    >
                      <div className="llmd_upload_right_content_print_left">
                        <IconButton>
                          <DescriptionOutlinedIcon />
                        </IconButton>
                        <h3>{file?.fileName}</h3>
                      </div>
                      <div className="llmd_upload_right_content_print_right">
                        <Tooltip title="Delete" placement="top" arrow>
                          <IconButton
                            onClick={() => handleDeleteFile(file?.fileId)}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="llmd_scrape">
              <div className="llmd_scrape_top">
                <div className="llmd_scrape_top_inputs">
                  <FormInputs
                    title={"Enter WebLink to Scrape here"}
                    placeholder="Enter Weblink"
                    type={"text"}
                    name="link"
                    value={uploadLink.link}
                    onChange={handleLinkChange}
                  />
                </div>
                <p>
                  Note: {""}
                  <span>Please enter one link at a time</span>
                </p>
                <div className="llmd_scrape_top_buttons">
                  <Buttons variant="secondary" onClick={handleLinkCancel}>
                    Cancel
                  </Buttons>
                  <Buttons variant="primary" onClick={handleUploadLink}>
                    Submit
                  </Buttons>
                </div>
              </div>
              <div className="llmd_scrape_content">
                {linkNames?.map((data, index) => (
                  <div key={index} className="llmd_scrape_content_print">
                    <div className="llmd_scrape_content_print_left">
                      <div className="llmd_scrape_content_print_left_icon">
                        <Tooltip title="Copy Link" placement="top" arrow>
                          <IconButton
                            onClick={() => handleCopyLink(data.scrapedLink)}
                          >
                            <LinkOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <h3>{data.scrapedLink}</h3>
                    </div>
                    <div className="llmd_scrape_content_print_right">
                      {data.status ? (
                        <h3>Link Scraped</h3>
                      ) : (
                        <h3>Still Scraping</h3>
                      )}
                      <Tooltip title="Delete" placement="top" arrow>
                        <IconButton
                          onClick={() => handleDeleteLink(data.scrapedIds)}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {confirmVisible && (
        <AdminConfirm
          title={"Are you sure you want to delete?"}
          onCancel={() => setConfirmVisible(false)}
          onConfirm={confirmActionHandler}
        />
      )}

      {userPopUp && (
        <div className="user">
          <div className="user_popup">
            <div className="user_popup_inner">
              <h2>Add User To This AI Agent</h2>
            </div>
            <div className="user_popup_body">
              <div className="user_popup_body_inputs">
                <FormInputs
                  title={"User Name"}
                  placeholder={"Enter User Name"}
                  type={"text"}
                  value={userInputs.userName}
                  name="userName"
                  onChange={handleUserChange}
                />
                {errors.userName && (
                  <span className="error">{errors.userName}</span>
                )}
              </div>
              <div className="user_popup_body_inputs">
                <FormInputs
                  title={"User Email"}
                  placeholder={"Enter User Email"}
                  type={"text"}
                  value={userInputs.userEmail}
                  name="userEmail"
                  onChange={handleUserChange}
                />
                {errors.userEmail && (
                  <span className="error">{errors.userEmail}</span>
                )}
              </div>
              {/* <div className="user_popup_body_inputs">
                <FormInputs
                  title={"User Password"}
                  placeholder={"Enter User Password"}
                  type={"password"}
                  value={userInputs.userPassword}
                  name="userPassword"
                  onChange={handleUserChange}
                />
                {errors.userPassword && (
                  <span className="error">{errors.userPassword}</span>
                )}
              </div> */}
            </div>
            <div className="user_popup_buttons">
              <button onClick={hanldeUserCancel}>Cancel</button>
              <button onClick={hanldeUserCreate}>Create</button>
            </div>
          </div>
        </div>
      )}
    </CustomLayout>
  );
};

export default LLMDetails;
