import React, { useEffect, useState, useMemo, useCallback } from "react";
import AdminLayout from "../../../Components/AdminLayout/AdminLayout";
import {
  APPROVE_ENTERPRISE_USER,
  DELETE_ENTERPRISE_USER,
  GET_ENTERPRISE_USERS,
  UPDATE_USER_ENABLE_STATUS,
} from "../../../utils/apiPath";
import { getApi, postApi } from "../../../utils/apiService";
import {
  useTable,
  useRowSelect,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import Icon from "../../../Components/Table/Icon";
import { errorToast, successToast } from "../../../services/ToastHelper";
import Switch from "react-switch";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import { debounce } from "lodash";
import AdminConfirm from "../../../Components/AdminConfirm/AdminConfirm";
import "./EnterpriseUsers.scss";

const EnterpriseUsers = () => {
  const [userDetails, setUserDetails] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmData, setConfirmData] = useState(null);

  const updateApprovalStatus = async (enterpriseId) => {
    const payload = {
      enterpriseId,
    };
    const { status, message } = await postApi(APPROVE_ENTERPRISE_USER, payload);
    if (status === 200) {
      successToast(message);
      fetchUserDetails(pageIndex, pageSize, searchQuery);
      return true;
    } else {
      errorToast(message);
      return false;
    }
  };

  const updateUserEnableStatus = async (enterpriseId, isEnable) => {
    const payload = {
      enterpriseId,
      isEnable,
    };
    const { status, message } = await postApi(
      UPDATE_USER_ENABLE_STATUS,
      payload
    );
    if (status === 200) {
      successToast(message);
      fetchUserDetails(pageIndex, pageSize, searchQuery);
      return true;
    } else {
      errorToast(message);
      return false;
    }
  };

  const handleDelete = async (enterpriseId) => {
    setConfirmData({ enterpriseId });
    setConfirmAction("delete");
    setConfirmVisible(true);
  };

  const confirmActionHandler = async () => {
    setConfirmVisible(false);
    const { enterpriseId, isEnable } = confirmData;

    if (confirmAction === "enable-disable") {
      await updateUserEnableStatus(enterpriseId, !isEnable);
    } else if (confirmAction === "delete") {
      await handleDeleteConfirm(enterpriseId);
    }
  };

  const handleDeleteConfirm = async (enterpriseId) => {
    const payload = { enterpriseId };
    const { status, message } = await postApi(DELETE_ENTERPRISE_USER, payload);
    if (status === 200) {
      successToast(message);
      fetchUserDetails(pageIndex, pageSize, searchQuery);
    } else {
      errorToast(message);
    }
  };

  const handleToggle = (data) => {
    const enterpriseId = data?.original?.enterpriseId;
    const isEnable = data?.original?.isEnable;
    setConfirmData({ enterpriseId, isEnable });
    setConfirmAction("enable-disable");
    setConfirmVisible(true);
  };

  const ApprovalSwitch = ({ row }) => {
    const [isApproved, setIsApproved] = useState(row.original.isApproved);
    const enterpriseId = row.original.enterpriseId;

    const handleApprovalToggle = async () => {
      if (!isApproved) {
        const success = await updateApprovalStatus(enterpriseId);
        if (success) {
          setIsApproved(true);
        }
      }
    };

    return (
      <Switch
        onChange={handleApprovalToggle}
        checked={isApproved}
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={25}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
        className="react-switch"
        disabled={isApproved}
      />
    );
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: "Sl No.",
        accessor: (row, index) => index + 1 + pageIndex * pageSize,
      },
      {
        Header: "Organization",
        accessor: "organization",
      },
      {
        Header: "Full Name",
        accessor: "fullName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Registration Status",
        accessor: "isActive",
        Cell: ({ value }) => <span>{value ? "Active" : "Inactive"}</span>,
      },
      {
        Header: "Approval Status",
        accessor: "isApproved",
        Cell: ({ row }) => <ApprovalSwitch row={row} />,
      },
      {
        Header: "Enable/Disable",
        accessor: "isEnable",
        Cell: ({ row }) => (
          <Switch
            onChange={() => handleToggle(row)}
            checked={row.original.isEnable}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
          />
        ),
      },
      // {
      //   Header: "Action",
      //   accessor: "action",
      //   Cell: ({ row }) => (
      //     <div className="">
      //       <Tooltip title="Delete" placement="top">
      //         <IconButton
      //           onClick={() => handleDelete(row.original.enterpriseId)}
      //         >
      //           <DeleteOutlined />
      //         </IconButton>
      //       </Tooltip>
      //     </div>
      //   ),
      // },
    ],
    [pageIndex, pageSize]
  );

  const fetchUserDetails = async (pageIndex, pageSize, searchQuery = "") => {
    setLoading(true);
    const { status, data } = await getApi(GET_ENTERPRISE_USERS, {
      params: { page: pageIndex, pageSize: pageSize, searchName: searchQuery },
    });
    if (status === 200) {
      setUserDetails(data?.usersData);
      setPageCount(data?.totalPages);
    } else {
      setUserDetails([]);
      setPageCount(0);
    }
    setLoading(false);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    prepareRow,
  } = useTable(
    {
      columns: COLUMNS,
      data: userDetails,
      manualPagination: true,
      pageCount,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: "selection",
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  useEffect(() => {
    fetchUserDetails(pageIndex, pageSize, searchQuery);
  }, [pageIndex, pageSize, searchQuery]);

  const handleSearchChange = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      setPageIndex(0);
    }, 500),
    []
  );

  return (
    <AdminLayout>
      <div className="table">
        <div className="table_container">
          <h4>Enterprise Management</h4>
          <div className="table_container_search">
            <input
              type="text"
              placeholder="Search by name..."
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </div>
        </div>
        <div className="table_sec">
          <div className="table_sec_cont">
            <div
              className="table_sec_cont_details"
              style={{ overflow: "hidden" }}
            >
              <table {...getTableProps}>
                <thead>
                  {headerGroups?.map((headerGroup, index) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={`header-${index}`}
                    >
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={`column-${index}`}
                          scope="col"
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {loading ? (
                    <tr>
                      <td
                        colSpan={COLUMNS.length}
                        className="loading-container"
                      >
                        <div className="loading-indicator">Loading...</div>
                      </td>
                    </tr>
                  ) : (
                    page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={`row-${index}`}>
                          {row.cells.map((cell, cellIndex) => (
                            <td
                              {...cell.getCellProps()}
                              key={`cell-${cellIndex}`}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table_page">
          <div className="table_page_section">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPageIndex(0);
              }}
            >
              {[10, 25, 50].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
            <span>
              Page{" "}
              <span>
                {pageIndex + 1} of {pageCount}
              </span>
            </span>
          </div>
          <ul>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(0)}
                disabled={pageIndex === 0}
              >
                <Icon icon="heroicons:chevron-double-left-solid" />
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex - 1)}
                disabled={pageIndex === 0}
              >
                Prev
              </button>
            </li>
            {pageOptions?.map((page) => (
              <li key={page}>
                <button
                  style={{
                    backgroundColor: page === pageIndex ? "#2D3748" : "#EDF2F7",
                    color: page === pageIndex ? "#fff" : "#2D3748",
                  }}
                  onClick={() => setPageIndex(page)}
                >
                  {page + 1}
                </button>
              </li>
            ))}
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex + 1)}
                disabled={pageIndex === pageCount - 1}
              >
                Next
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageCount - 1)}
                disabled={pageIndex === pageCount - 1}
              >
                <Icon icon="heroicons:chevron-double-right-solid" />
              </button>
            </li>
          </ul>
        </div>
      </div>
      {confirmVisible && (
        <AdminConfirm
          title={`Are you sure you want to ${
            confirmAction === "delete"
              ? "delete this user?"
              : "perform this action?"
          }`}
          onCancel={() => setConfirmVisible(false)}
          onConfirm={confirmActionHandler}
        />
      )}
    </AdminLayout>
  );
};

export default EnterpriseUsers;
