import React from "react";
import UserTable from "../../../Components/Table/Table";
import AdminLayout from "../../../Components/AdminLayout/AdminLayout";

const AdminUserManagement = () => {
  return (
    <div>
      <AdminLayout>
        <UserTable />
      </AdminLayout>
    </div>
  );
};

export default AdminUserManagement;
