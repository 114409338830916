// eslint-disable react-hooks/exhaustive-deps
import React, { createContext, useState, useEffect } from "react";
import { getApi } from "../utils/apiService";
import { SUBSCRIPTION_STATUS } from "../utils/apiPath";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [subscriptionStatus, setSubscriptionStatus] = useState();

  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (token) {
      fetchStatusData();
    }
  }, [token]);

  const fetchStatusData = async () => {
    const { status, data } = await getApi(SUBSCRIPTION_STATUS);
    if (status === 200 && data) {
      setSubscriptionStatus(data);
    } else {
      setSubscriptionStatus([]);
    }
  };

  return (
    <UserContext.Provider
      value={{
        subscriptionStatus,
        setSubscriptionStatus,
        fetchStatusData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
