import React, { useEffect, useState, useMemo } from "react";
import AdminLayout from "../../../Components/AdminLayout/AdminLayout";
import { useTable, useRowSelect, useSortBy, usePagination } from "react-table";
import Icon from "../../../Components/Table/Icon";
import { getApi } from "../../../utils/apiService";
import { GET_SUBSCRIPTION_USERS } from "../../../utils/apiPath";

const AdminPaymentDetails = () => {
  const [subscriberDetails, setSubscriberDetails] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const COLUMNS = useMemo(
    () => [
      {
        Header: "Sl No.",
        accessor: (row, index) => index + 1 + pageIndex * pageSize,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        Cell: ({ value }) => (value ? formatDate(value) : "N/A"),
      },
      {
        Header: "End Date",
        accessor: "endDate",
        Cell: ({ value }) => (value ? formatDate(value) : "N/A"),
      },
      {
        Header: "Subscription Status",
        accessor: "status",
        Cell: ({ value }) => <span>{value ? "Active" : "Inactive"}</span>,
      },
    ],
    [pageIndex, pageSize]
  );

  const fetchUserDetails = async (pageIndex, pageSize) => {
    setLoading(true);
    const { status, data } = await getApi(GET_SUBSCRIPTION_USERS, {
      params: { page: pageIndex, pageSize: pageSize },
    });
    if (status === 200) {
      setSubscriberDetails(data?.usersData);
      setPageCount(data?.totalPages);
    } else {
      setSubscriberDetails([]);
      setPageCount(0);
    }
    setLoading(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().padStart(4, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    prepareRow,
  } = useTable(
    {
      columns: COLUMNS,
      data: subscriberDetails,
      manualPagination: true,
      pageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    fetchUserDetails(pageIndex, pageSize);
  }, [pageIndex, pageSize]);

  return (
    <AdminLayout>
      <div className="table">
        <div className="table_container">
          <h4>Subscription Details</h4>
        </div>
        <div className="table_sec">
          <div className="table_sec_cont">
            <div
              className="table_sec_cont_details"
              style={{ overflow: "hidden" }}
            >
              <table {...getTableProps}>
                <thead>
                  {headerGroups?.map((headerGroup, index) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={`header-${index}`}
                    >
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={`column-${index}`}
                          scope="col"
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {loading ? (
                    <tr>
                      <td
                        colSpan={COLUMNS.length}
                        className="loading-container"
                      >
                        <div className="loading-indicator">Loading...</div>
                      </td>
                    </tr>
                  ) : (
                    page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={`row-${index}`}>
                          {row.cells.map((cell, cellIndex) => (
                            <td
                              {...cell.getCellProps()}
                              key={`cell-${cellIndex}`}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table_page">
          <div className="table_page_section">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPageIndex(0);
              }}
            >
              {[10, 25, 50].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
            <span>
              Page{" "}
              <span>
                {pageIndex + 1} of {pageCount}
              </span>
            </span>
          </div>
          <ul>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(0)}
                disabled={pageIndex === 0}
              >
                <Icon icon="heroicons:chevron-double-left-solid" />
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex - 1)}
                disabled={pageIndex === 0}
              >
                Prev
              </button>
            </li>
            {pageOptions?.map((page) => (
              <li key={page}>
                <button
                  style={{
                    backgroundColor: page === pageIndex ? "#2D3748" : "#EDF2F7",
                    color: page === pageIndex ? "#fff" : "#2D3748",
                  }}
                  onClick={() => setPageIndex(page)}
                >
                  {page + 1}
                </button>
              </li>
            ))}
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex + 1)}
                disabled={pageIndex === pageCount - 1}
              >
                Next
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageCount - 1)}
                disabled={pageIndex === pageCount - 1}
              >
                <Icon icon="heroicons:chevron-double-right-solid" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminPaymentDetails;
